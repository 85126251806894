import { API } from 'aws-amplify';
import moment from 'moment';
import printJS from 'print-js';
import React, { useEffect, useRef, useState } from 'react';
import BarcodeReader from "react-barcode-reader";
import { Col, Container, Form, FormLabel, InputGroup, Row } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import toast from 'react-hot-toast';
import { useDispatch, useSelector } from 'react-redux';
import uniqid from "uniqid";
import { ButtonList } from '../../components/ButtonList';
import ShipmentStatus from "../../data/shipment-status.json";
import { PAGE_SIZE, PAGE_TITLE, getDistanceFromHere } from '../../helpers';
import { storeShipper } from '../../stores/slice';
import PrintLabel from './PrintLabel';
import Shipmentpackages from './Shipmentpackages';
import ReactGA from "react-ga4"
import { processBilling } from '../../helpers/processBilling';

const getDeliveryServicesQuery = /* GraphQL */ `
  query MyQuery ($shipperId: ID! $filter: ModelDeliveryServiceFilterInput ) {
    deliveryServicesByShipperId (
		shipperId: $shipperId
		filter: $filter
		) {
      items {
        id
        type
        name
		value
        pickupBy
        tatMin
        tatMax
        maxDistance
		sort
		active
        carrierId
		carrier {
			id
			name
			alias
			users(filter: {role: {eq: OWNER}}) {
				items {
					user {
						id
						name
					}
					role
				}
			}
		}
      }
      nextToken
    }
  }
`;

const updateShipment = /* GraphQL */ `
  mutation UpdateShipment(
    $input: UpdateShipmentInput!
    $condition: ModelShipmentConditionInput
  ) {
    updateShipment(input: $input, condition: $condition) {
      id
    }
  }
`;

const sendShipmentCarrierUserNotification = /* GraphQL */ `
  query SendShipmentCarrierUserNotification($shipmentId: ID!) {
    sendShipmentCarrierUserNotification(shipmentId: $shipmentId)
  }
`;

const sendShipmentPatientNotification = /* GraphQL */ `
  query SendShipmentPatientNotification($shipmentId: ID!) {
    sendShipmentPatientNotification(shipmentId: $shipmentId)
  }
`;

const sendSlackAlert = /* GraphQL */ `
  mutation SendSlackAlert($input: SendSlackAlertInput!) {
    sendSlackAlert(input: $input)
  }
`;

const createShipmentStatusHistory = /* GraphQL */ `
  mutation CreateShipmentStatusHistory(
    $input: CreateShipmentStatusHistoryInput!
    $condition: ModelShipmentStatusHistoryConditionInput
  ) {
    createShipmentStatusHistory(input: $input, condition: $condition) {
      id
      status
      description
      attachment
      signature
      createdTime
      userId
      user {
        image
        name
      }
    }
  }
`;

const createUpsLabelMutation = /* GraphQL */ `
mutation CreateUpsLabel($id: ID!) {
  createUpsLabel(id: $id)
}
`;

const createEndiciaLabelMutation = /* GraphQL */ `
  mutation CreateEndiciaLabel($id: ID!) {
    createEndiciaLabel(id: $id)
  }
`;

const createFedexLabelMutation = /* GraphQL */ `
  mutation createFedexLabel ($id: ID!) {
    createFedexLabel (id: $id)
  }
`;

const getShipmentQuery = /* GraphQL */ `
  query GetShipment($id: ID!) {
    getShipment(id: $id) {
      id
      extTrackingNumber
      extLabelUrl
    }
  }
`;

const getShipperQuery = /* GraphQL */ `
  query MyQuery($id: ID!) {
    getShipper(id: $id) {
		id
		name
     	settings {
			items {
				key
				value
			}
		}
    }
  }
`;


export default function Dispatch() {

    const dispatch = useDispatch()
    const printBtnRef = useRef()

    const myUser = useSelector((state) => state.slice.USER);
    const myShipper = useSelector((state) => state.slice.SHIPPER);
    const myShippers = useSelector((state) => state.slice.SHIPPERS);

    const [shipment, setShipment] = useState(null)
    const [deliveryServices, setDeliveryServices] = useState([])
    const [carriers, setCarriers] = useState([])
    const [shipperSettings, setShipperSettings] = useState([])
    const [packageErrors, setPackageErrors] = useState([]);

    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/dispatch",
        })
        document.title = `Awe Print ${PAGE_TITLE}`;
    }, [])



    const getShipperSetting = async () => {
        let { data } = myShipper?.shipper?.id && await API.graphql({ query: getShipperQuery, variables: { id: myShipper?.shipper?.id } })
        setShipperSettings(data?.getShipper?.settings?.items)
    }

    const ShipFromSection = () => {
        const handleShipFromChange = async (shipperId) => {
            const index = myShippers.findIndex(x => x.shipper?.id === shipperId);
            const shipper = myShippers[index];
            dispatch(storeShipper(shipper));
        }

        return <section className='mb-3'>
            <h4>Shipper</h4>
            <Form.Select value={myShipper?.shipper?.id} onChange={(e) => handleShipFromChange(e.target.value)}>
                {myShippers.map((item, index) => <option key={index} value={item?.shipper?.id}>{item?.shipper?.name}</option>)}
            </Form.Select>
            <hr />
        </section>
    }

    const CarrierSection = ({ carriers, carrierId, deliveryServices }) => {
        const handleCarrierChange = async (carrier) => {
            const deliveryService = deliveryServices.filter(x => x.carrierId === carrier.id)
            const { expectedPickupTime, expectedDeliveryTime } = getPickupDeliveryTime(deliveryService);

            let shipFrom = shipment?.shipFrom?.address?.location
            if (shipment?.shipper?.alias?.toLowerCase() === "aurora mail order pharmacy" && shipment?.shipTo?.address?.state === 'IL') shipFrom = { latitude: 42.047586035877956, longitude: -87.98072053317183 }

            let distance = await getDistanceFromHere(shipFrom, shipment?.shipTo?.address?.location)

            setShipment({
                ...shipment,
                distance,
                deliveryServiceId: deliveryService[0].id,
                deliveryService: deliveryService[0],
                carrierId: carrier?.id,
                carrier: carrier,
                expectedPickupTime: expectedPickupTime,
                expectedDeliveryTime: expectedDeliveryTime
            })
        }

        const handleDeliveryServiceChange = (deliveryService) => {
            const { expectedPickupTime, expectedDeliveryTime } = getPickupDeliveryTime(deliveryService);
            setShipment({
                ...shipment,
                deliveryServiceId: deliveryService.id,
                deliveryService: deliveryService,
                expectedPickupTime: expectedPickupTime,
                expectedDeliveryTime: expectedDeliveryTime
            })
        }

        const handlePickupDateChange = (selectedDates, dateStr) => {
            const expectedPickupString = `${dateStr} ${shipment?.deliveryService?.pickupBy}`
            const expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
            const expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(shipment?.deliveryService?.tatMax, 'hours')

            setShipment({
                ...shipment,
                expectedPickupTime: expectedPickupTime.unix(),
                expectedDeliveryTime: expectedDeliveryTime.unix()
            })
        }

        return (
            <section className='mb-3'>
                <h4>Courier Partner</h4>
                {<ButtonList items={carriers} value={carrierId} onChange={handleCarrierChange} />}
                {
                    <div>
                        <hr />
                        <h4>Delivery Type</h4>
                        <ButtonList items={deliveryServices?.filter(x => x.carrierId === shipment?.carrierId)}
                            value={shipment?.deliveryServiceId} onChange={handleDeliveryServiceChange} />
                    </div>
                }

                {
                    shipment?.deliveryService?.type === 'FUTURE' && <div>
                        <hr />
                        <h4>Deliver On</h4>
                        <InputGroup className='mb-3'>
                            <InputGroup.Text>
                                <i className='fe fe-calendar' />
                            </InputGroup.Text>
                            <Flatpickr className='form-control' onChange={handlePickupDateChange}
                                value={moment.unix(shipment?.expectedPickupTime).toDate()}
                                options={{
                                    altInput: true,
                                    altFormat: 'F j, Y',
                                    minDate: moment().add(1, 'days').toDate(),
                                }}>
                                <input data-input className='form-input' />
                            </Flatpickr>
                        </InputGroup>
                    </div>
                }

                <hr />
            </section>
        )
    }

    const handleScan = async (data) => {
        if (data.includes("RXR")) {
            let loading = toast.loading("Loading...")
            try {
                let keyword = data;
                if (keyword.indexOf("^") >= 0) keyword = data.split("^")[0];
                if (keyword.indexOf("RXR") >= 0) keyword = keyword.replace("RXR", "");
                const apiName = 'api';
                const path = `/search/shipment?size=${PAGE_SIZE}`;

                let init = {
                    body: {
                        sort: [
                            {
                                "createdTime": {
                                    "order": "desc",
                                    "unmapped_type": "date"
                                }
                            }
                        ],
                        query: {
                            bool: {
                                must: [{ match: { shipperId: myShipper.shipper.id } }]
                            }
                        }
                    }
                };

                init.body.query.bool["filter"] = { "terms": { "items.number.keyword": [keyword?.trim()] } }

                const { hits } = await API.post(apiName, path, init);
                const sourceData = hits?.hits?.length > 0 ? hits?.hits?.map((item) => item?._source) : [];

                if (sourceData.length !== 0) {
                    let data = sourceData[0]
                    if (data?.status === 'OPEN' || data?.status === 'READY_FOR_PICKUP') {
                        await getServiceTypes();
                        await getShipperSetting();
                        setShipment(data)
                    } else toast.error(`Shipment is ${ShipmentStatus[data.status]}`)
                } else toast.error("No shipment found !")
            } catch (error) {
                console.log(error)
                toast.error("Something went wrong!")
            } finally { toast.dismiss(loading) }
        } else if (shipment) {
            if (shipment?.history?.items.find((item) => item?.status === 'READY_FOR_PICKUP')) return toast.error('Shipment label is already generated!')
            let items = data.split("^")
            let rx = items.at(-1).split(",")
            let allRx = shipment?.items || [[]]
            const isPhoxTail = shipperSettings.findIndex(x => x.key === 'phox_tail_required');

            if (isPhoxTail >= 0 && shipperSettings[isPhoxTail].value === 'Yes') {
                if (shipment?.items?.length > 0 && Array.isArray(shipment?.items?.[shipment?.items?.length - 1])) {
                    const resultArray = [];
                    for (let i = 0; i < items.length; i += 2) {
                        const key = items[i];
                        const values = items[i + 1]?.split(',');
                        const obj = {};
                        obj[key] = values;
                        resultArray.push(obj);
                    }
                    const updatedItems = resultArray[0]?.RXN?.map((rxn, index) => ({
                        id: 'NEW',
                        name: 'Product',
                        number: rxn?.trim(),
                        ndc: resultArray[1]?.NDC[index]?.trim(),
                        qty: resultArray[2]?.QTY[index]?.trim(),
                        tier: 0
                    })) || [];

                    let lastItems = shipment?.items?.[shipment.items.length - 1] || [];
                    if (lastItems.length === 1 && !lastItems[0].number && !lastItems[0].ndc && !lastItems[0].qty) {
                        lastItems = [];
                    }
                    allRx[allRx?.length - 1] = [...lastItems, ...updatedItems];
                }

            } else if (shipment?.items?.length > 0 && Array.isArray(shipment?.items?.[0])) {
                for (let item of rx) {
                    let allNumbers = allRx.map((rx) => rx.map(item => item.number)).flat()
                    if (!allNumbers.includes(item)) {
                        allRx.at(-1).push({ number: item, name: 'Product', id: 'rx' })
                    }

                }
            } else {
                let allNumbers = allRx.map((item => item.number))
                for (let item of rx) {
                    if (!allNumbers.includes(item)) {
                        allRx.push({ number: item, name: 'Product', id: 'rx' })
                    }
                }

            }
            setShipment((prev) => ({ ...prev, items: allRx }))
        }
    };

    const handleScanError = (error) => {
        console.error(error);
    };

    const getServiceTypes = async () => {
        try {
            let res = await API.graphql({ query: getDeliveryServicesQuery, variables: { shipperId: myShipper?.shipper?.id, filter: { active: { eq: true } } } })
            let deliveryServicesRes = res.data.deliveryServicesByShipperId?.items
            let allCarriers = [];
            for (let item of deliveryServicesRes) {
                if (allCarriers.findIndex(x => x.id === item.carrierId) === -1) {
                    allCarriers.push(item.carrier)
                }
            }

            setCarriers(allCarriers)
            setDeliveryServices(deliveryServicesRes)
            return deliveryServicesRes
        } catch (error) {
            console.log(error);
        }
    }

    const handleItems = (items,) => {
        setShipment({ ...shipment, items })
    }

    const handlePackageCount = (items, packageCount) => {
        setShipment({ ...shipment, packageCount: packageCount, items })
    }

    const scanPackage = (data) => {
        const dataArray = data.split('^');
        const resultArray = [];
        for (let i = 0; i < dataArray.length; i += 2) {
            const key = dataArray[i];
            const values = dataArray[i + 1]?.split(',');
            const obj = {};
            obj[key] = values;
            resultArray.push(obj);
        }
        let updatedParcelCount = [[]];
        for (let i = 0; i < resultArray[0].RXN.length; i++) {
            updatedParcelCount[0].push({
                id: 'NEW',
                name: 'Product',
                number: resultArray[0].RXN[i],
                ndc: resultArray[1].NDC[i],
                qty: resultArray[2].QTY[i]
            });
        }

        if (updatedParcelCount.length > 0) {
            setShipment({
                ...shipment,
                items: updatedParcelCount.length > 0 ? updatedParcelCount : [[{ id: 'NEW', name: 'Product', number: '', ndc: '', qty: '' }]],
            });
        } else {
            toast.error('No data')
        }
    }

    const getPickupDeliveryTime = (deliveryService) => {
        let expectedPickupTime = null
        let expectedDeliveryTime = null

        if (deliveryService?.type === 'FUTURE') return { expectedPickupTime, expectedDeliveryTime }

        if (deliveryService?.type === 'TODAY') {
            if (deliveryService.tatMax <= 4) {
                expectedPickupTime = moment()
                expectedDeliveryTime = moment().add(deliveryService.tatMax, 'hours')
            } else {
                expectedPickupTime = moment().add(1, 'hours')
                expectedDeliveryTime = moment().add(deliveryService.tatMax + 1, 'hours')
                if (expectedDeliveryTime.isAfter(moment.tz(expectedPickupTime, myShipper?.shipper?.timezone?.id).endOf('day'))) {
                    expectedDeliveryTime = moment.tz(expectedPickupTime, myShipper?.shipper?.timezone?.id).endOf('day')
                }
            }
        } else if (deliveryService?.type === 'TOMORROW') {
            const dateString = moment().add(1, 'days').format('YYYY-MM-DD')
            const expectedPickupString = `${dateString} ${deliveryService?.pickupBy}`
            expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
            expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(deliveryService?.tatMax, 'hours')
        } else if (deliveryService?.type === 'MONDAY') {
            const dateString = moment().add(8 - moment().isoWeekday(), 'days').format('YYYY-MM-DD')
            const expectedPickupString = `${dateString} ${deliveryService?.pickupBy}`
            expectedPickupTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id)
            expectedDeliveryTime = moment.tz(expectedPickupString, shipment?.shipFrom?.timezone?.id).add(deliveryService?.tatMax, 'hours')
        }
        return {
            expectedPickupTime: expectedPickupTime?.unix(),
            expectedDeliveryTime: expectedDeliveryTime?.unix()
        }
    }

    const updateStatus = async () => {
        let tasks = [];

        // 1. Update Shipment
        let inputShipment = {
            id: shipment?.id,
            status: 'READY_FOR_PICKUP',
            readyForPickupTime: moment().unix()
        }
        tasks.push(API.graphql({ query: updateShipment, variables: { input: inputShipment } }))

        // 2. Create Shipment Status History
        let inputShipmentHistory = {
            id: uniqid(),
            shipmentId: shipment.id,
            userId: myUser.id,
            status: 'READY_FOR_PICKUP',
            createdTime: moment().unix(),
            description: 'Shipment label has been printed'
        }
        tasks.push(API.graphql({ query: createShipmentStatusHistory, variables: { input: inputShipmentHistory } }))

        // 3. Shipment Carrier User Notification
        if (shipment.deliveryService.type === 'TODAY' && shipment.status === 'OPEN') {
            tasks.push(API.graphql({ query: sendShipmentCarrierUserNotification, variables: { shipmentId: shipment.id } }))
        }

        // 4. Send Patient Notification
        tasks.push(API.graphql({ query: sendShipmentPatientNotification, variables: { shipmentId: shipment.id } }))

        // 5. Slack Notification
        let slackAlert = (shipment?.deliveryService?.type === 'TODAY' && shipment?.deliveryService?.tatMax <= 4) ? 'critical-alerts' : 'alerts'
        const slackInput = {
            channel: slackAlert,
            message: `Shipment ${shipment?.number} (${shipment?.deliveryService?.name}) is READY_FOR_PICKUP`,
            user: shipment?.shipFrom?.name
        }
        tasks.push(API.graphql({ query: sendSlackAlert, variables: { input: slackInput } }));
        tasks.push(API.graphql({ query: sendSlackAlert, variables: { input: slackInput } }));

        // Process Tasks
        await Promise.all(tasks).then(() => {
            toast.success(`Status has been updated to ${ShipmentStatus['READY_FOR_PICKUP']}`);
            setShipment(null);
        }).catch((error) => {
            console.error(error);
        })
    }

    const printLabel = async (url, update) => {
        if (update) await updateStatus();
        if (!url) return window.print()

        if (url?.endsWith('.gif')) {
            let imgTag = document.createElement("img")
            let div = document.createElement("div")
            imgTag.src = url
            imgTag.id = 'printJS-form'
            imgTag.style.height = '100vh !important'
            imgTag.style.scale = 1.22
            imgTag.style.marginLeft = '4rem'
            imgTag.style.marginTop = '2rem'
            div.append(imgTag)
            document.body.appendChild(div)
            const style = document.createElement('style');
            style.innerHTML = `
            @media print {
            @page {
                width: 6in !important;
                height: 4in !important;
                }
                .shipment-label{
                display : none !important; 
                } 
                #printJS-form {
                visibility: visible !important;
                width : 90%
                }
                html,body{
                height : 99% !important;
                }
            }`;
            document.head.appendChild(style);
            setTimeout(() => {
                window.print()
                document.head.removeChild(style);
                document.body.removeChild(div)
            }, 1000)
        } else {
            printJS(url)
        }
    }

    const handleValidate = async (carrierId) => {
        const { expectedPickupTime, expectedDeliveryTime } = getPickupDeliveryTime(shipment?.deliveryService);
        if (shipment?.weight == 0) {
            toast.error('Please enter valid weight!')
            return false;
        }
        if (shipment?.carrier?.name === 'USPS') {
            if (shipment?.weight > 1.0 && shipment?.deliveryService?.name === 'First-Class Mail') {
                toast.error("Service type not supported for the given weight")
                return false
            }
        }
        let selectedCarrier = carriers?.find((item) => item.id === carrierId)
        if (shipment?.shipper?.alias?.toLowerCase() === "aurora mail order pharmacy" && ["WI", "IL"].includes(shipment?.shipTo?.address?.state)) {
            if (selectedCarrier?.name?.includes("WI")) {
                let distance = await getDistanceFromHere(shipment?.shipTo?.address?.location, shipment?.shipFrom?.address?.location);
                if (distance > 55) {
                    toast.error("Distance is more then 55 mi, please select another Courier")
                    return false
                }
            } else if (selectedCarrier?.name?.includes("IL")) {
                const chicagoLocation = { latitude: 42.047586035877956, longitude: -87.98072053317183 }
                const chicagoDistance = await getDistanceFromHere(chicagoLocation, shipment?.shipTo?.address?.location);
                if (chicagoDistance > 55) {
                    toast.error("Distance is more then 55 mi, please select another Courier")
                    return false
                }
            }
        }
        if (shipment?.deliveryService?.type === 'FUTURE' && (!expectedDeliveryTime && !expectedPickupTime)) {
            toast.error('Please select a future date')
            return false
        }

        const isPhoxTail = shipperSettings.findIndex(x => x.key === 'phox_tail_required');
        if (isPhoxTail >= 0 && shipperSettings[isPhoxTail].value === 'Yes') {
            let errorList = [];
            const numberMap = new Map();
            shipment?.items.forEach((pkg, pkgIndex) => {
                pkg.forEach((item, itemIndex) => {
                    let errors = {};
                    // Validate fields
                    if (!item.number || item.number.trim() === '') {
                        errors.number = 'Reference no. is required.';
                    }
                    if (!item.ndc || item.ndc.trim() === '') {
                        errors.ndc = 'NDC is required.';
                    }
                    if (!item.qty || item.qty.trim() === '') {
                        errors.qty = 'QTY is required.';
                    }
                    // Check for duplicate reference number
                    const refNumber = item.number;
                    if (refNumber && numberMap.has(refNumber)) {
                        errors.duplicateReferenceNumber = 'Duplicate reference number.';
                    } else if (refNumber) {
                        numberMap.set(refNumber, { pkgIndex, itemIndex });
                    }
                    // Add errors to errorList if there are any
                    if (Object.keys(errors).length > 0) {
                        errorList.push({ pkgIndex, itemIndex, errors });
                    }
                });
            });
            setPackageErrors(errorList);
            if (errorList.length > 0) {
                toast.error("Duplicate reference number or missing reference number, NDC, or QTY.");
                return false
            }
        }

        return true
    }

    const handlePrint = async () => {
        if (shipment?.history?.items.find((item) => item?.status === 'READY_FOR_PICKUP')) return printLabel(shipment?.extLabelUrl, true);
        if (!await handleValidate(shipment?.carrierId)) return
        let loading = toast.loading("Please wait while label is being generated");
        try {
            // 1. Update Shipment
            await API.graphql({
                query: updateShipment, variables: {
                    input: {
                        id: shipment?.id,
                        items: JSON.stringify(shipment?.items),
                        weight: shipment?.weight || null,
                        carrierId: shipment?.carrierId,
                        deliveryServiceId: shipment?.deliveryServiceId
                    }
                }
            })

            // process billing
            await processBilling(shipment?.id)

            // 2. Generate Label
            if (
                shipment?.carrier?.alias.toLowerCase() === "ups"
                || shipment?.carrier?.alias.toLowerCase() === "fedex"
                || shipment?.carrier?.alias?.toLowerCase() === "usps"
            ) {
                if (shipment?.weight) {
                    if (shipment?.carrier?.name.toLowerCase() === "ups") await API.graphql({ query: createUpsLabelMutation, variables: { id: shipment?.id } })
                    if (shipment?.carrier?.name === "FedEx") await API.graphql({ query: createFedexLabelMutation, variables: { id: shipment?.id } })
                    if (shipment?.carrier?.name?.toLowerCase() === "usps") {
                        let response = await API.graphql({ query: createEndiciaLabelMutation, variables: { id: shipment?.id } })
                        let data = JSON.parse(response.data.createEndiciaLabel)
                        if (data.statusCode === 400) {
                            console.error(data.body)
                            return toast.error(data.body)
                        }
                    }
                    // 3. Update Status
                    await updateStatus();

                    const shipmentResponse = await API.graphql({ query: getShipmentQuery, variables: { id: shipment?.id } })
                    printLabel(shipmentResponse.data.getShipment.extLabelUrl, false)
                } else {
                    return toast.error("Please enter the package weight");
                }
            } else {
                // 3. Update Status
                await updateStatus();

                return window.print();
            }
        } catch (error) {
            toast.error('Oops! There was an error trying to process your request!')
            console.error(error)
        } finally {
            toast.dismiss(loading)
        }
    }

    useEffect(() => {
        const handleKeyDown = (e) => {
            if (e.key.toLowerCase() === "enter") printBtnRef?.current?.click()
        }
        window.addEventListener("keydown", handleKeyDown)
        return () => window.removeEventListener('keydown', handleKeyDown)
    }, [])

    return (
        <>
            <div className="container-fluid dispatch_container">
                {
                    shipment && (
                        <div className="row justify-content-center">
                            <div className="col-12 col-lg-10 col-xl-8">
                                <div className="header mt-md-5">
                                    <div className="header-body">
                                        <div className="row align-items-center">
                                            <div className="col">
                                                <h6 className="header-pretitle">Shipment</h6>
                                                <h1 className="header-title">#{shipment?.number}</h1>
                                            </div>
                                            <div className="col-auto">
                                                <button className="btn btn-dark" ref={printBtnRef} onClick={() => handlePrint()}>
                                                    Print
                                                </button>
                                                <button className="ms-2 btn btn-light" onClick={() => setShipment(null)}>
                                                    Clear
                                                </button>
                                            </div>
                                        </div>
                                    </div>
                                </div>
                                {/* Content */}
                                {shipment?.history?.items.find((item) => item?.status === 'READY_FOR_PICKUP') && <p className='text-danger'>Note : Shipment label is already generated !</p>}
                                <div className="card card-body p-5" style={{ pointerEvents: shipment?.history?.items.find((item) => item?.status === 'READY_FOR_PICKUP') ? 'none' : "all" }}>

                                    <div className="row">
                                        <div className="col-6">
                                            <Form.Label>Patient Name</Form.Label>
                                            <Form.Control readOnly type='text' placeholder='e.g. 2' value={shipment?.customer?.name || ''} />
                                        </div>
                                        <div className='col-6'>
                                            <Form.Label>Patient MRN</Form.Label>
                                            <Form.Control readOnly type='text' placeholder='e.g. 2' value={shipment?.customer?.extId || ''} />
                                        </div>
                                    </div>
                                    <Row className='mt-3'>
                                        <Col>
                                            <Form.Group>
                                                <FormLabel>Address Line 1</FormLabel>
                                                <Form.Control readOnly type='text' name='address2' placeholder='e.g. Suite 100' value={shipment?.shipTo?.address?.address1 || ''} />
                                            </Form.Group>
                                        </Col>
                                        <Col>
                                            <Form.Group>
                                                <FormLabel>Address Line 2</FormLabel>
                                                <Form.Control readOnly type='text' name='address2' placeholder='e.g. Suite 100' value={shipment?.shipTo?.address?.address2 || ''} />
                                            </Form.Group>
                                        </Col>
                                    </Row>

                                    <Row className='mt-3'>
                                        <Col lg={4}>
                                            <Form.Group>
                                                <FormLabel>City</FormLabel>
                                                <Form.Control readOnly type='text' name='city' placeholder='e.g. Seattle' value={shipment?.shipTo?.address?.city || ''} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group>
                                                <FormLabel >State</FormLabel>
                                                <Form.Control readOnly type='text' name='state' placeholder='e.g. WA' value={shipment?.shipTo?.address?.state || ''} />
                                            </Form.Group>
                                        </Col>
                                        <Col lg={4}>
                                            <Form.Group>
                                                <FormLabel>Postal Code</FormLabel>
                                                <Form.Control readOnly type='text' name='postalCode' placeholder='e.g. 98105' value={shipment?.shipTo?.address?.postalCode || ""} />
                                            </Form.Group>
                                        </Col>
                                    </Row>
                                    <hr className="my-5" />
                                    <CarrierSection carrierId={shipment?.carrierId} carriers={carriers} deliveryServices={deliveryServices} />
                                    <div className="row">
                                        <div className="col-12">
                                            <h6 className="text-uppercase text-body-secondary">Package Weight (in lbs)</h6>
                                            <input autoFocus={shipment?.history?.items.find((item) => item?.status === 'READY_FOR_PICKUP') ? false : true} type="number" value={shipment?.weight} className='form-control w-25' onChange={(e) => setShipment((prev) => ({ ...prev, weight: e.target.value }))} />
                                            {shipment?.items && shipment?.items?.length > 0 && Array.isArray(shipment?.items[0]) ?
                                                <Shipmentpackages packages={shipment?.items} packageCount={shipment.packageCount} handleItems={handleItems} myShipper={myShipper} handlePackages={handlePackageCount} scanPackageData={scanPackage} packageErrors={packageErrors} setPackageErrors={setPackageErrors} /> :
                                                <>
                                                    <div className='d-flex justify-content-between align-items-center mt-4'>
                                                        <h4 className='m-0'>Packages</h4>
                                                        <button className='btn btn-sm btn-light' type='button' onClick={() => {
                                                            let shipmentClone = structuredClone(shipment)
                                                            let items = shipmentClone.items
                                                            items.push({ number: "", name: 'Product', id: 'rx' })
                                                            setShipment((prev) => ({ ...prev, items }))
                                                        }}><i className='fe fe-plus'></i></button>
                                                    </div>
                                                    {shipment?.items?.map((item, idx) => (
                                                        <div className='d-flex gap-2 my-2'>
                                                            <Form.Control type='text' name='state' placeholder='e.g. name' value={item?.name || ''} onChange={(e) => {
                                                                let shipmentClone = structuredClone(shipment)
                                                                let items = shipmentClone.items
                                                                items[idx].name = e.target.value
                                                                setShipment((prev) => ({ ...prev, items }))
                                                            }} />
                                                            <Form.Control type='text' name='state' placeholder='e.g. number' value={item?.number || ''} onChange={(e) => {
                                                                let shipmentClone = structuredClone(shipment)
                                                                let items = shipmentClone.items
                                                                items[idx].number = e.target.value
                                                                setShipment((prev) => ({ ...prev, items }))
                                                            }} />
                                                            <button className='btn btn-light btn-sm' type='button' onClick={() => {
                                                                let shipmentClone = structuredClone(shipment)
                                                                let items = shipmentClone.items
                                                                items = items.filter((item, index) => index !== idx)
                                                                setShipment((prev) => ({ ...prev, items }))
                                                            }}><i className='fe fe-trash'></i></button>
                                                        </div>
                                                    ))}
                                                </>
                                            }
                                        </div>
                                    </div>
                                </div>
                            </div>
                        </div>
                    )
                }

                {
                    shipment === null && (
                        <Container>
                            <div className='m-3'>
                                <ShipFromSection />
                            </div>
                            <h2 className='text-center'>Scan QR </h2>
                            <hr />
                            <div className='w-100 text-center'>
                                <img className='m-2' src="/img/scanning.gif" alt="animated" />
                            </div>
                        </Container>
                    )
                }

                <BarcodeReader onError={handleScanError} onScan={handleScan} />
            </div >

            <PrintLabel shipment={shipment} qrCode={shipment?.id} />
        </>
    )
}
