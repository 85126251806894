import jsPDF from 'jspdf';
import moment from "moment";
import React, { useEffect, useState } from "react";
import {
    Button, Col, Container,
    ListGroup, Row
} from 'react-bootstrap';
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { AddressView } from "../../components/Address";
import { Card, MultiButtonCard } from "../../components/Card";
import ListGroupItem from "../../components/ListGroupItem";
import ShipmentStatus from "../../data/shipment-status.json";
import StorageType from "../../data/storage-type.json";
import {
    formatNumber,
    isEditor,
    isOwner,
    isViewer,
    statusButtonColor,
    toLocalDate,
    toLocalDateTime,
    toLocalTime
} from "../../helpers";

const DownloadPOD = ({ shipment, multi_Dimensional_Shipment_Item, downloadPodPdf, setDownloadPodPdf }) => {
    const myShipper = useSelector((state) => state.slice.SHIPPER);

    const ShipmentHeader = () => {
        return (
            <>
                <nav className="header mb-0">
                    <Container fluid>
                        <div className="header-body">
                            <Row className="align-items-center">
                                <Col>
                                    <h1 className="mb-1">{`#${shipment?.number}`}</h1>
                                    <h2 className="text-muted mb-1">
                                        {shipment?.shipTo?.name || "-"}
                                    </h2>
                                </Col>
                                <Col>
                                    <div className="small text-muted">
                                        <AddressView address={shipment?.shipTo?.address} />
                                    </div>
                                </Col>
                                <Col>
                                    <div className="small text-muted">
                                        {shipment?.shipTo?.email && (
                                            <div>
                                                {shipment?.shipTo?.email}
                                            </div>
                                        )}
                                        {shipment?.shipTo?.phone?.length > 10 && (
                                            <div>
                                                {formatNumber(shipment?.shipTo?.phone)}
                                            </div>
                                        )}
                                    </div>
                                </Col>

                                <Col className="col-auto">
                                    <Button className={`ms-2 btn btn-sm ${statusButtonColor(shipment?.status)}`}>
                                        {shipment?.status === "EXCEPTION" ? "Cancelled" : ShipmentStatus[shipment?.status]}
                                    </Button>
                                </Col>
                            </Row>
                        </div>
                    </Container>
                </nav >

            </>
        );
    };

    const ShipmentDetails = () => {

        let storageTypes =
            ((['REFRIGERATED', 'COLD_CHAIN', 'ROOM_TEMPERATURE'].includes(shipment?.status))) &&
            shipment?.services?.map((key, index) =>
                index !== shipment?.services?.length - 1
                    ? StorageType[key] === undefined
                        ? ""
                        : StorageType[key] + ", "
                    : StorageType[key]
            );

        let trackingUrl = "";
        if (
            shipment?.carrier?.name?.toLowerCase() === "fedex" &&
            shipment?.extTrackingNumber
        ) {
            trackingUrl = `https://www.fedex.com/fedextrack/?trknbr=${shipment?.extTrackingNumber}`;
        } else if (
            shipment?.carrier?.name?.toLowerCase() === "usps" &&
            shipment?.extTrackingNumber
        ) {
            trackingUrl = `https://tools.usps.com/go/TrackConfirmAction.action?tLabels=${shipment?.extTrackingNumber}`;
        } else if (
            shipment?.carrier?.name?.toLowerCase() === "ups" &&
            shipment?.extTrackingNumber
        ) {
            trackingUrl = `https://www.ups.com/track?track=yes&trackNums=${shipment?.extTrackingNumber}`;
        }

        return (
            <>
                <Card
                    title="Shipment Details"
                    bodyPadding="py-0"
                    buttonName={(['OPEN', 'READY_FOR_PICKUP', 'OUT_FOR_DELIVERY'].includes(shipment?.status)) && (isOwner(myShipper?.role) || isEditor(myShipper?.role)) ? "Edit" : ""}

                >
                    <ListGroup className="list-group-flush">
                        <ListGroupItem name="Shipper" value={shipment?.shipFrom?.name} />
                        <ListGroupItem
                            name="Delivery Service"
                            value={shipment?.deliveryService?.name}
                        />
                        <ListGroupItem
                            name="Order / Reference Number"
                            value={shipment?.extId}
                        />
                        <ListGroupItem
                            name="Adult Signature"
                            value={
                                shipment?.services?.includes("ADULT_SIGNATURE")
                                    ? "Required"
                                    : "-"
                            }
                        />
                        <ListGroupItem
                            name="Package Type"
                            value={storageTypes ? storageTypes : "-"}
                        />
                        <ListGroupItem
                            name="Weight"
                            value={shipment?.weight ? `${shipment?.weight} lbs` : "-"}
                        />
                        <ListGroupItem
                            name="Distance"
                            value={`${shipment?.distance} mi`}
                        />
                        <ListGroupItem
                            name="Tracking Number"
                            value={shipment?.extTrackingNumber || "-"}
                            url={trackingUrl}
                        />
                        <ListGroupItem
                            name="Delivery Instructions"
                            value={shipment.deliveryInstructions || "-"}
                        />
                    </ListGroup>
                </Card>


            </>
        );
    };

    const MultiDimensionalShipmentItems = () => {


        return (
            <>
                <div>
                    <div className="d-flex justify-content-between py-3 align-items-center">
                        <h4>
                            {`Package content (${shipment?.items?.length})`}
                        </h4>
                    </div>
                    {shipment?.items?.map((box, boxIndex) => (
                        <div className="card" key={boxIndex}>
                            <div div className="card-header d-flex justify-content-between pb-0" >
                                <h4>{`Package ${boxIndex + 1}`}</h4>
                            </div>

                            <div className="card-body px-4 py-0">

                                <ListGroup className="list-group-flush py-0" key={boxIndex}>
                                    {
                                        box?.map((item, index) => (
                                            <ListGroupItem
                                                key={index}
                                                name={`${index + 1} - ${item?.name}`}
                                                value={item.number}
                                            />
                                        ))
                                    }
                                </ListGroup>
                            </div>

                        </div >
                    ))}

                </div >


            </>
        );
    };

    const ShipmentItems = () => {
        return (
            <>
                <Card
                    title="Items"
                    bodyPadding="py-0"
                    buttonName={
                        (shipment?.status === "READY_FOR_PICKUP" || shipment?.status === "OPEN") &&
                            (!isViewer(myShipper?.role))
                            ? "Edit"
                            : ""
                    }
                >
                    <ListGroup className="list-group-flush">
                        {shipment?.items?.map((item, index) => (
                            <ListGroupItem
                                key={index}
                                name={`${index + 1} - ${item?.name}`}
                                value={item.number}
                            />
                        ))}
                    </ListGroup>
                </Card >
            </>
        );
    };

    const ShipmentRating = () => {
        const rating =
            shipment?.ratings?.items?.length > 0 ? shipment?.ratings?.items[0] : null;
        return (
            <Card title="Customer Rating">
                <p>{rating?.feedback || "-"}</p>
                <hr />
                <ListGroupItem name='Rating' value={`${rating?.rating || 0}/5`} />
                <hr />
                <ListGroupItem name='NPS' value={`${(rating?.nps ?? 0) * 10}/100`} />
            </Card>
        );
    };

    const RecentActivity = () => {
        return (
            <>
                <Card title="Recent Activity">
                    <ListGroup className="list-group-flush my-n3">
                        {
                            shipment?.history?.sort((a, b) => b.createdTime - a.createdTime)
                                .map((item, index) => {
                                    return (
                                        <ListGroup.Item key={index}>
                                            <Row>
                                                <Col className="activity-alignment" >
                                                    <h5>
                                                        {ShipmentStatus[item.status]}
                                                    </h5>
                                                    <div className="small text-muted">
                                                        <div>{item.description}</div>
                                                        <div className="mt-2">
                                                            {item?.user?.name || "Admin"}
                                                        </div>
                                                    </div>
                                                </Col>
                                                <Col className="col-auto activity-alignment activity-margin">
                                                    <div className="small ">
                                                        {toLocalTime(
                                                            item?.createdTime,
                                                            shipment?.shipFrom?.timezone?.id
                                                        )}
                                                    </div>
                                                    <div className="small text-muted ">
                                                        {toLocalDate(
                                                            item?.createdTime,
                                                            shipment?.shipFrom?.timezone?.id
                                                        )}
                                                    </div>
                                                </Col>
                                            </Row>
                                        </ListGroup.Item>
                                    );
                                })}
                    </ListGroup>
                </Card>
            </>
        );
    };

    const ShipmentStats = ({ shipment }) => {
        const [ShipmentRoute, setShipmentRoute] = useState(shipment?.route);
        const hours = Math.floor(ShipmentRoute?.routeDuration / 60);
        const mins = ShipmentRoute?.routeDuration % 60;
        const eta = hours > 0 ? `${hours} hr ${mins} min` : `${mins} min`;

        let deliveryStatus;

        if (ShipmentRoute?.routeSequence > 1) {
            deliveryStatus = `The courier is ${ShipmentRoute?.routeSequence} stops and ${eta} away`
        } else if (ShipmentRoute?.routeSequence === 1) {
            deliveryStatus = `Courier should arrive in about  ${eta}`
        }

        let pickuptime;
        const readyForPickupItem = shipment?.history?.find(item => item?.status === "READY_FOR_PICKUP");
        if (readyForPickupItem) {
            pickuptime = moment(readyForPickupItem?.createdAt).unix();
        }

        const tat = (shipment?.status === 'DELIVERED' && shipment?.actualDeliveryTime) ? moment.unix(shipment?.actualDeliveryTime).diff(moment.unix(pickuptime), 'minutes') : '-';
        const hrs = tat && Math.floor(tat / 60);
        const min = tat && tat % 60;
        const tatData = hrs > 0 ? `${hrs} hr, ${min} ${min === 1 ? `min` : `min(s)`}` : `${min} ${min === 1 ? `min` : `min(s)`}`;

        return (
            <>
                {shipment?.status === "READY_FOR_PICKUP" && (
                    <ListGroupItem name="Estimated Pickup By"
                        value={`${toLocalDateTime(
                            shipment?.expectedPickupTime,
                            shipment?.shipFrom?.timezone?.id
                        )}`}
                    />
                )}

                {shipment?.status === "IN_TRANSIT" && (
                    <ListGroupItem
                        name="Estimated Delivery By"
                        value={`${toLocalDateTime(
                            shipment?.expectedDeliveryTime,
                            shipment?.shipFrom?.timezone?.id
                        )}`}
                    />
                )}

                {shipment?.status === "OUT_FOR_DELIVERY" && (

                    <ListGroupItem
                        name="Estimated Delivery By"
                        value={`${deliveryStatus || toLocalDateTime(
                            shipment?.expectedDeliveryTime,
                            shipment?.shipFrom?.timezone?.id
                        )}`}
                    />
                )}

                {shipment?.status === "DELIVERED" && (
                    <>
                        <ListGroupItem
                            name="Delivered At"
                            value={toLocalDateTime(
                                shipment?.actualDeliveryTime,
                                shipment?.shipFrom?.timezone?.id,
                                shipment?.shipFrom?.timezone?.alias
                            )}
                        />
                        <hr />
                        <ListGroupItem
                            name="Turnaround Time"
                            value={(tatData && !isNaN(tat) && tatData)}
                        />
                    </>
                )}

                {shipment?.status === "RETURNED" && (
                    <ListGroupItem
                        name="Returned At"
                        value={toLocalDateTime(
                            shipment?.returnedTime,
                            shipment?.shipFrom?.timezone?.id,
                            shipment?.shipFrom?.timezone?.alias
                        )}
                    />
                )}
            </>
        );
    };

    const CarrierDriver = () => {

        return (
            <>
                <MultiButtonCard title="Courier Details" >
                    <div>
                        <ListGroupItem name="Courier Name" value={shipment?.carrier ? shipment?.carrier?.name : "-"} />
                        {
                            !['usps', 'ups', 'fedex'].includes(shipment?.carrier?.name.toLowerCase()) &&
                            <>
                                <hr />
                                <ListGroupItem name="Driver Name" value={shipment?.driver?.name || "Unassigned"} />
                            </>
                        }
                        {shipment?.status !== ('OPEN' || 'OUT_FOR_RETURN') &&
                            <>
                                <hr />
                                <ShipmentStats shipment={shipment} />
                            </>
                        }
                    </div>
                </MultiButtonCard >
            </>
        );
    };

    const DeliveryProof = () => {
        const [history, setHistory] = useState();
        const [base64URL, setBase64URL] = useState('');
        useEffect(() => {
            const index = shipment?.history?.findIndex(
                (x) => x.status === "DELIVERED" || 'RETURNED'
            );
            if (index >= 0) {
                let historyItem = shipment?.history[index];
                if (historyItem.signature && typeof historyItem.signature === "string")
                    historyItem.signature = JSON.parse(historyItem.signature);
                setHistory(historyItem);
            }
        }, []);

        let closedByOtp = false;
        const otp = shipment?.history?.find(item => item?.description?.toLowerCase() === "closed by pin");
        if (otp) { closedByOtp = true; }


        useEffect(() => {
            shipment?.attachment?.length > 0 && base64Img(shipment?.attachment[0])
        }, [])

        const base64Img = (url) => {
            return new Promise((resolve, reject) => {
                if (shipment?.attachment.length > 0) {
                    fetch(url)
                        .then(response => response.blob())
                        .then(blob => {
                            const reader = new FileReader();
                            reader.onloadend = () => {
                                const base64String = reader.result;
                                resolve(base64String);
                                setBase64URL(base64String)
                            };
                            reader.readAsDataURL(blob);
                        })
                        .catch(error => {
                            console.error('Error fetching image:', error);
                            reject(error);
                        });
                } else {
                    reject(new Error('No attachment available'));
                }
            });
        };

        return (
            <>
                <Card title="Proof of Delivery" bodyPadding="py-0">
                    <ListGroup className="list-group-flush">
                        <ListGroupItem
                            key="name"
                            name="Signed By"
                            value={history?.signature?.name || "-"}
                        />
                        <ListGroupItem
                            key="relationship"
                            name="Relationship"
                            value={history?.signature?.relationship || "-"}
                        />
                        <ListGroupItem
                            key="isContactLess"
                            name="Contactless"
                            value={
                                history?.signature?.isContactLess ? history?.signature?.isContactLess === "true" ? "Yes" : "No" : '-'
                            }
                        />
                        <ListGroupItem
                            key="reason"
                            name="Reason"
                            value={history?.signature?.reason || "-"}
                        />
                        {closedByOtp &&
                            <ListGroupItem
                                key='verification_code'
                                name='One-Time Passcode'
                                value={closedByOtp ? shipment?.authCode : '-'} />
                        }
                    </ListGroup>
                    {shipment?.attachment?.length > 0 && (
                        <div className="position-relative my-3">
                            {base64URL && <img src={base64URL} alt="base img" style={{ height: '90px', maxWidth: '120px' }} />}
                        </div>
                    )}
                </Card>
            </>
        );
    };

    let pdf = new jsPDF('p', 'pt', 'a4');
    const pdfWidth = pdf.internal.pageSize.getWidth()
    const pdfHeight = pdf.internal.pageSize.getHeight()

    useEffect(() => { setTimeout(() => { downloadPodPdf && generatePdf() }, 700) }, [downloadPodPdf])

    const generatePdf = () => {
        let html = document.getElementById(`shipmentPOD`);
        if (html) {
            pdf.html(html, {
                callback: function (pdf) {
                    pdf.save(`shipment#${shipment?.number}.pdf`);
                    setDownloadPodPdf(false)
                },
                autoPaging: 'text',
            });
        } else {
            toast.error('Something went wrong')
        }
    }

    let actualDeliveryLocation = null;
    shipment?.history?.map((history) => { history.status === 'DELIVERED' && (actualDeliveryLocation = history?.location) })

    return (
        <div>
            <div>
                <div className='d-none'>
                    <div id={`shipmentPOD`} style={{
                        width: pdfWidth,
                        height: pdfHeight,
                        padding: '20px',
                    }}>

                        <ShipmentHeader />
                        <div className="googlemap my-3" >
                            {actualDeliveryLocation ?
                                <img src={`https://maps.googleapis.com/maps/api/staticmap?size=560x300&maptype=roadmap&markers=color:green%7C${actualDeliveryLocation?.latitude},${actualDeliveryLocation?.longitude}&markers=color:red%7C${shipment?.shipTo?.address?.location?.latitude},${shipment?.shipTo?.address?.location?.longitude}&format=png&style=feature:poi|element:labels|visibility:off&key=${process.env.REACT_APP_GOOLGE_API_KEY}`} alt='Phox-Map' />
                                : <img src={`https://maps.googleapis.com/maps/api/staticmap?zoom=16&size=560x300&maptype=roadmap%20&markers=color:green%7C${shipment?.shipTo?.address?.location?.latitude},${shipment?.shipTo?.address?.location?.longitude}&format=png&style=feature:poi|element:labels|visibility:off&key=${process.env.REACT_APP_GOOLGE_API_KEY}`} alt='Phox-Map' />
                            }
                        </div>
                        <Row>
                            <Col xl={4} lg={6} md={4} sm={4} >
                                <ShipmentDetails />
                                {
                                    multi_Dimensional_Shipment_Item ? <MultiDimensionalShipmentItems /> : <ShipmentItems />
                                }
                            </Col>
                            <Col xl={4} lg={6} md={4} sm={4}>
                                <CarrierDriver />
                                <RecentActivity />
                            </Col>
                            <Col xl={4} lg={6} md={4} sm={4} >
                                <ShipmentRating />
                                <DeliveryProof />
                            </Col>
                        </Row>
                    </div>
                </div>
            </div>
        </div>
    )
}

export default DownloadPOD
