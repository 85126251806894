/* eslint-disable react-hooks/exhaustive-deps */

import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Row, Table } from 'react-bootstrap';
import { useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { TableKeywordSearch, TableShipperFilter, TableShipperGroupFilter } from '../../components/TableFilter';
import { handleApiError, isOwner } from '../../helpers';
import { isEmpty } from 'lodash';
import ReactGA from "react-ga4"

const shipperUsersByShipperId = /* GraphQL */ `
  query ShipperUsersByShipperId( $shipperId: ID! $limit: Int  ) {
    shipperUsersByShipperId( shipperId: $shipperId limit: $limit ) {
      items {
        id
        role
        shipperId
        userId
		user{
			id
			name
			email
			phone
		}
      }
    }
  }
`;

export const getShipperGroup = /* GraphQL */ `
  query GetShipperGroup($id: ID!) {
    getShipperGroup(id: $id) {
      id
      name
	  users {
		 items {
			user {
			id
			email
			name
			role
				}
			}
		}
      shippers {
		   items {
			     name
				users {
					items {
						user {
							id
							email
							name
							role
						}
					}
				}
        	}
    	}
    }
  }
`;


const UserList = () => {

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/user",
		})
	}, [])

	const myShipper = useSelector((state) => state.slice.SHIPPER);
	const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP);
	const navigate = useNavigate()

	const [users, setUsers] = useState([]);
	const [spinner, showSpinner] = useState(true);
	const [keyword, setKeyword] = useState('')

	useEffect(() => getUsers(), [myShipper, myShipperGroup, keyword])

	const getUsers = () => {
		if (myShipperGroup?.shipperGroup?.id && !myShipper?.shipper?.id) {
			if (keyword) {
				const foundUsers = users?.filter(user => user?.user?.name?.toLowerCase().includes(keyword.toLowerCase()));
				setUsers(foundUsers);
			} else {
				showSpinner(true);
				API.graphql({ query: getShipperGroup, variables: { id: myShipperGroup?.shipperGroup?.id } })
					.then((res) => {
						let allUser = [];

						const shippers = res?.data?.getShipperGroup?.shippers?.items || [];
						const groupUser = res?.data?.getShipperGroup?.users?.items || [];

						allUser = [
							...groupUser.map((user) => ({
								...user,
								role: user?.user?.role,
							})),
							...shippers.flatMap((shipper) =>
								shipper?.users?.items?.map((item) => ({
									...item,
									role: item?.user?.role,
								})) || []
							),
						];
						const uniqueUsers = [];
						const ids = new Set();
						for (const user of allUser) {
							if (!ids.has(user?.user?.id)) {
								ids.add(user?.user?.id);
								uniqueUsers.push(user);
							}
						}
						setUsers(uniqueUsers.sort((a, b) => b?.user?.name - a?.user?.name));
					})
					.catch(handleApiError)
					.finally(() => showSpinner(false))
			}

		} else if (myShipper?.shipper?.id || keyword) {
			if (keyword) {
				const foundUsers = users?.filter(user => user?.user?.name?.toLowerCase().includes(keyword.toLowerCase()));
				setUsers(foundUsers);
			} else {
				showSpinner(true);
				API.graphql({ query: shipperUsersByShipperId, variables: { shipperId: myShipper.shipper.id } })
					.then((res) => setUsers(res?.data?.shipperUsersByShipperId?.items))
					.catch(handleApiError)
					.finally(() => showSpinner(false))
			}
		} else {
			showSpinner(true)
			const promises = [];
			if (!isEmpty(myShipper)) {
				for (let item of myShipper) {
					const promise = API.graphql({ query: shipperUsersByShipperId, variables: { shipperId: item?.shipper?.id } })
						.then((res) => {
							return res.data.shipperUsersByShipperId.items;
						});
					promises.push(promise);
				}
				Promise.all(promises)
					.then((results) => {
						const temp = results.flat();
						setUsers(temp)
						showSpinner(false);
					})
					.catch((error) => {
						console.error("Error:", error);
						showSpinner(false);
					});
			}
		}
	}

	return (
		<>
			<PageHeader name='Users' pageTitle={"User List"}>
				{isOwner(myShipper?.role) && <Link className='btn btn-dark ms-2' to='/user/invite'>Invite User</Link>}

				<Button className='btn btn-light ms-2' onClick={() => { getUsers() }}>
					<i className='fe fe-refresh-cw'></i>
				</Button>
			</PageHeader>

			<Container fluid>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
							{myShipperGroup?.shipperGroup?.id && <TableShipperGroupFilter />}
							<TableShipperFilter hideAll={myShipperGroup?.shipperGroup?.id ? true : false} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm'>
							<thead>
								<tr>
									<th>Name</th>
									<th>Email</th>
									<th className='text-center'>Role</th>
								</tr>
							</thead>
							<tbody>
								{
									users && users?.map((user, index) => {
										return (
											<tr key={index} onClick={() => { isOwner(myShipper?.role) && navigate(`/user/${user?.user?.id}/overview`) }} style={{ cursor: 'pointer' }}>
												<td>
													{user?.user?.name}
													<div className='small text-muted'>
														{user?.user?.title} {user?.user?.company}
													</div>
													<div className='small text-muted'></div>
													<div className='small text-muted'></div>
												</td>
												<td>{user?.user?.email}</td>
												<td className='text-center'>
													{user?.role ? <span className='text-capitalize bg-light p-2 rounded'>{user?.role}</span> : "-"}
												</td>
											</tr>
										)
									})
								}
							</tbody>
						</Table>
					</Spinner>
				</Card>
			</Container>
		</>
	)
}

export default UserList;
