import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, ListGroup, Row, Table } from 'react-bootstrap';
import ReactGA from "react-ga4";
import { StatCard } from '../../components/Card';
// import { CarrierFilter, CommunicationFilter, DateFilter, LogTypeFilter, ShipperFilter, ShipperGroupFilter } from '../../components/EntityFilter';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import {  CommunicationStatusBadge, LogTypeStatusBadge } from '../../components/StatusBadge';
import { TableClearFilter, TableDateFilter, TableKeywordSearch, TablePagination, TableShipperFilter, TableShipperGroupFilter, TimeZoneFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, getTZ, toLocalDate, toLocalTime} from '../../helpers/index';
import { CommunicationFilter, LogTypeFilter, UserCarrierFilter } from '../../components/FilterWithIds';
import { useDispatch, useSelector } from 'react-redux';
import { storeShipper } from '../../stores/slice';
import SideDrawer from '../../components/SideDrawer';


const deliveryServicesByShipperId = /* GraphQL */ `
  query DeliveryServicesByShipperId(
    $shipperId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelDeliveryServiceFilterInput
    $limit: Int
    $nextToken: String
  ) {
    deliveryServicesByShipperId(
      shipperId: $shipperId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        type
        name
        value
        pickupBy
        tatMin
        tatMax
        maxDistance
        default
        sort
        shipperId
        carrierId
        carrier{
            id
            name
            alias
            image
			active
			 timezone {
          alias
          id
          name
        }
        }
        createdAt
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const CommunicationLog = () => {

    const myShipper = useSelector((state) => state.slice.SHIPPER);
    const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP)
	  const myShippers = useSelector((state) => state.slice.SHIPPERS);



    const [spinner, showSpinner] = useState(false);
    const [communicationLogData, setCommunicationLogData] = useState([])
    const [facets, setFacets] = useState({})
    const [tz, setTz] = useState("EST");
    const [keyword, setKeyword] = useState('')
    const [shipperGroup, setShipperGroup] = useState()
    const [shipper, setShipper] = useState()
    const [carrier, setCarrier] = useState()
    const [status, setStatus] = useState();
    const [logType, setLogType] = useState();
    const [pageNumber, setPageNumber] = useState(0);
    const [pageCount, setPageCount] = useState();

    const [resetDate, setRestDate] = useState(0);
    const [timeZone, setTimeZone] = useState("America/New_York");
    const [selectedComms, setSelectedComms] = useState(null);
    const [drawerOpen, setDrawerOpen] = useState(false);
    const [selectedLogType, setSelectedLogType] = useState('');
    const [dateFilters, setDateFilters] = useState({
        fromDate: moment().tz(timeZone)?.startOf('day').unix(),
        toDate: moment().tz(timeZone)?.endOf('day').unix()
    });
	const [carrierList, setCarrierList] = useState([])
  const dispatch = useDispatch()


    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: "/communication-log",
        })
    }, [])

    useEffect(() => {
        const getTimeZone = async () => {
            const timez = await getTZ(shipperGroup?.value, shipper?.value, carrier?.value);
            if (timez) {
                setTimeZone(timez?.id);
                setTz(timez?.alias)
            }
            else {
                setTimeZone("America/New_York")
                setTz("EST")
            }
        };

        if (shipperGroup?.value || shipper?.value || carrier?.value) getTimeZone();
    }, [shipperGroup?.value, shipper?.value, carrier?.value,myShipperGroup]);

    useEffect(() => {
        setPageNumber(0);
        getElasticComms();
    }, [shipperGroup, shipper, carrier, status,logType,myShipperGroup,myShipper]);

    useEffect(() => {
      setTimeZone(myShipper?.shipper?.timezone?.id || carrier?.timezone?.id)
      setTz(myShipper?.shipper?.timezone?.alias || carrier?.timezone?.alias)
    }, [carrier, myShipperGroup])

    useEffect(() => {
      if (myShipperGroup?.shipperGroup?.timezone?.id) {
        setTimeZone(myShipperGroup?.shipperGroup?.timezone?.id)
        setTz(myShipperGroup?.shipperGroup?.timezone?.alias)
      } else if (myShipper?.shipper) {
        setTimeZone(myShipper?.shipper?.timezone?.id)
        setTz(myShipper?.shipper?.timezone?.alias)
      }
      if (myShipper?.shipper?.id) { getServices() }
    }, [myShipper, myShipperGroup])

    useEffect(() => {
        const delay = setTimeout(() => { setPageNumber(0); getElasticComms(); }, DELAY_TIMEOUT)
        return () => clearTimeout(delay)
    }, [keyword, dateFilters])

    useEffect(() => {
        getElasticComms();
    }, [pageNumber]);

    useEffect(() => {
        if (!shipperGroup || !shipper)
            setTimeZone("America/New_York");
        setTz("EST");
    }, [shipper, shipperGroup])

    	useEffect(() => {
		if (myShipper) {
			getServices()
			setTimeZone(myShipper?.shipper?.timezone?.id)
			setTz(myShipper?.shipper?.timezone?.alias)
		}
	}, [myShipper])

    const getServices = () => {
		setCarrier(null)
		myShipper?.shipper?.id && API.graphql({ query: deliveryServicesByShipperId, variables: { shipperId: myShipper?.shipper?.id } }).then((response) => {
			const deliveryServices = response.data.deliveryServicesByShipperId?.items.sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }));

			let carriers = [];
			deliveryServices.forEach((item) => {
				if (!carriers.find(x => x?.id === item?.carrier?.id))
					carriers.push(item.carrier)
			});

			carriers = carriers.filter((carrier) => carrier?.active !== false).sort((a, b) => a?.name?.localeCompare(b?.name, undefined, { sensitivity: 'accent' }))
			setCarrierList(carriers)
		})
	}

  const getElasticComms = async () => {
    showSpinner(true);
    try {
        const apiName = 'api';
        const path = `/search/communication-log?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
        let init = {
            body: {
                aggs: {
                    type: {
                        terms: {
                            "field": "type.keyword",
                            "size": 10000
                        }
                    }
                   
                },
                sort: [
                  {
                    "_score": { "order": "desc" }
                  },
                  {
                    "createdTime": {
                      "order": "desc",
                      "unmapped_type": "date"
                    }
                  }
                ],
                query: {
                    bool: {

                        must: [],
                        must_not: [],
                        "filter": []

                    }
                }
            }
        };

        if (!keyword) {
          init.body.query.bool.must.push({
            range: {
              "createdTime": {
                "gte": dateFilters?.fromDate,
                "lte": dateFilters?.toDate
              }
            }
          })
        }
  

        if (myShipperGroup?.shipperGroup?.id) init.body.query.bool.must.push({ match: { shipperGroupId: myShipperGroup?.shipperGroup?.id } })
        if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper?.id } })
        if (carrier?.id) init.body.query.bool.must.push({ match: { carrierId: carrier?.id } })
        if (keyword) init.body.query.bool.filter.push({ "term": { "to.keyword": keyword } })
        if (shipper) init.body.query.bool.must.push({ match: { shipperId: shipper?.value } })
        if (shipperGroup) init.body.query.bool.must.push({ match: { shipperGroupId: shipperGroup?.value } })
        if (status) init.body.query.bool.must.push({ match: { status: status?.value } });
        if (logType) init.body.query.bool.must.push({ match: { type: logType?.value } });

        const data = await API.post(apiName, path, init);

        let statusCount = {};
        data.aggregations.type.buckets?.forEach((item) => {
            statusCount[item.key] = item.doc_count
        })
        setFacets(statusCount)
        setCommunicationLogData(data?.hits?.hits);
        setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
        showSpinner(false);

    } catch (error) {
        console.error('ealstic error', error);
        showSpinner(false);

    }
}
    const clearFilters = () => {
        setDateFilters({
            fromDate: moment().tz('America/New_York')?.startOf('day').unix(),
            toDate: moment().tz('America/New_York')?.endOf('day').unix()
        });
        setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
        setShipperGroup(null);
        setShipper(null);
        setCarrier(null);
        setStatus(null);
        setLogType(null)
        setTimeZone("America/New_York");
        setTz("EST");
		    setKeyword("");
		   dispatch(storeShipper(myShippers))
        
    }

    const tzHandle = (e) => {
        setTimeZone(e);
    };
  
    const handleRowClick = (shipment) => {
      setSelectedComms(shipment._source); 
      setDrawerOpen(true); 
    };

    return (
     <>
         <PageHeader name='Communication Logs' shipperLabel={shipperGroup?.label}>
                <Row>
                {myShipperGroup?.shipperGroup?.id &&<TableShipperGroupFilter/>}
                <TableShipperFilter hideAll={myShipperGroup?.shipperGroup?.id ? true : false}/>
             <UserCarrierFilter onChange={setCarrier} value={carrier} carrierList={carrierList} />
                    <CommunicationFilter value={status} onChange={setStatus} />
                    <LogTypeFilter value={logType} onChange={setLogType} />
				  	<TableDateFilter key={resetDate} timezone={myShipper?.shipper?.timezone?.id} onChange={setDateFilters} startOf={0} />
                    <TimeZoneFilter
                    	title={""}
                        setTimeZone={setTimeZone}
                        dark={true}
                        onChange={tzHandle}
                        tz={tz}
                        setTz={setTz}
                    />
                    <TableClearFilter onClick={clearFilters} /> 
                </Row>
            </PageHeader>
        <Container fluid>
        <Row>
            <StatCard title='Email' value={(facets?.EMAIL || 0) }/>
            <StatCard title='SMS' value={(facets?.SMS || 0) } />
            <StatCard title='Call' value={facets?.CALL || 0} />
          </Row>
          <Card>
         <Card.Header>
                <TableKeywordSearch keyword={keyword} onChange={setKeyword} />     
         </Card.Header>
            <Spinner display={spinner}>
              <Table responsive size='sm' className='mb-0'>
                <thead>
                  <tr>
                    <th className='text-center'>Shipment #</th>
                    <th className='text-center'>From</th>
                    <th className='text-center'>To</th>
                    <th className='text-center'>Subject</th>
                    <th  className='text-center'>Sent At</th>
                    <th className='text-center'>Log Type</th>
                    <th className='text-center'>Status</th>
                  </tr>
                </thead>
                <tbody>
                  {communicationLogData?.length > 0 && communicationLogData.map((shipment) => {
                    return (
                      <tr key={shipment._source.id} style={{ cursor: 'pointer' }} onClick={() => handleRowClick(shipment)}>
                        <td className='text-center'>
                            	<div>
														{shipment?._source?.isGreenPhox ? <img src="img/greenphox.svg" alt="green-phox-icon rounded-circle" className="mx-2" height={'20px'} width={'20px'} /> : <> </>}
                            {shipment._source?.shipment?.number}
													</div>
                            {shipment.batch_number > 0 && (
                                <div className="small text-muted">
                                    Batch #{shipment?._source?.batch_number}
                                </div>
                            )}
                        </td>
                        <td className='text-center'>{shipment._source.from}</td>
                        <td className='text-center'>
                          <div>{shipment._source.to}</div>
                        </td>
                        <td className='text-center'>{shipment._source.subject}</td>
                        <td className='text-center'>
                        {toLocalTime(shipment._source?.createdTime,	myShipper?.shipper?.timezone?.id || myShipperGroup?.shipperGroup?.timezone?.id)}
                        <div className='small text-muted'>{toLocalDate(shipment._source?.createdTime)}</div>

                        </td>
                        <td className='text-center'>
                        <LogTypeStatusBadge status={shipment._source?.type} />
                        </td>
                        <td className='text-center'>
                          <CommunicationStatusBadge status={shipment._source.status} />
                        </td>
                      </tr>
                    );
                  })}
                </tbody>
                <TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={8} />
              </Table>
            </Spinner>
          </Card>
        </Container>
        {/* Drawer Component */}
        <SideDrawer
            show={drawerOpen}
            handleClose={() => setDrawerOpen(false)}
            items={selectedComms}
            setSelectedLogType={setSelectedLogType}
            selectedLogType={selectedLogType}
         />
    </>
    )
}

export default CommunicationLog
