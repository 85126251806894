import React, { useEffect, useState } from 'react'
import { Col, ListGroup, Row } from 'react-bootstrap';
import { getBadgeIcons, getBadgeStatus, getIconBadgeColor, getStatusBadgeColor, toExportDateTime, toLocalDate, toLocalTime } from '../helpers';
import { useSelector } from 'react-redux';

const SideDrawer = ({ show, handleClose, items,setSelectedLogType,selectedLogType}) => {

      const [formateShipment,setFormateShipment] = useState()
        setSelectedLogType(items?.type)

        useEffect(() => {
        // To check events is already parsed or not
          const formattedEvents = items?.events?.map(eventString => {
              try {
                  return typeof eventString === "string" ? JSON.parse(eventString) : eventString;
              } catch (error) {
                  console.error("Failed to parse eventString:", error);
                  return eventString; // Return as is if parsing fails
              }
          });
          setFormateShipment(formattedEvents);

      }, [items]);


       // To disable scrolling when the drawer is open
       useEffect(() => {
         if (show) {
           document.body.style.overflow = 'hidden';
         } else {
           document.body.style.overflow = 'auto';
         }
       }, [show]);
     
       // Inline styles for drawer and backdrop
       const drawerStyle = {
           position: 'fixed',
           top: 0,
           right: 0, 
           transform: show ? 'translateX(0)' : 'translateX(100%)', 
           width: '450px',
           height: '100%',
           backgroundColor: '#fff',
           boxShadow: '-2px 0 5px rgba(0, 0, 0, 0.5)',
           zIndex: 1050,
           transition: 'transform 0.4s ease', 
           padding: '20px',
         };
       
         const backdropStyle = {
           position: 'fixed',
           top: 0,
           left: 0,
           width: '100%',
           height: '100%',
           backgroundColor: 'rgba(0, 0, 0, 0.5)',
           zIndex: 1040,
           opacity: show ? 1 : 0, 
           transition: 'opacity 0.4s ease', 
           pointerEvents: show ? 'auto' : 'none', 
         };
     
       return (
         <>
           <div style={backdropStyle} onClick={handleClose}></div>
           <div style={drawerStyle}>
            <button
               style={{
                   fontSize: '1.5rem', 
               }}
               type="button"
               className="btn-close"
               aria-label="Close"
               onClick={handleClose}
               ></button>
                 

             {items && (
               <div>
                 <p
                 className='cursor-pointer'
   
                 onClick={()=>{
                   window.open(`/shipment/${items?.shipmentId}`, '_self');
   
                 }}
                 style={{fontSize:32 ,textDecoration:'underline'}}>#{items.shipment?.number}</p>
                <table class="table table-bordered">
                    <tbody>
                        <tr>
                          <td>Shipper</td>
                          <td> {items?.shipperGroup?.name}</td>
                        </tr>
                        <tr>
                          <td>Subject</td>
                          <td> {items?.subject}</td>
                        </tr>
                        <tr>
                          <td>From</td>
                          <td>{items?.from}</td>
                        </tr>
                        <tr>
                          <td>To</td>
                          <td>{items?.to}</td>
                        </tr>
                    </tbody>
                    </table>
               </div>
             )}
             {CommunicationLogHistory(formateShipment,selectedLogType)}
           </div>
         </>
       );
     };
   
   export default SideDrawer


   const CommunicationLogHistory = (items,selectedLogType) => {
    let sortedEvents = items?.sort((a, b) => a.timestamp - b.timestamp);
    const myShipper = useSelector((state) => state.slice.SHIPPER);
    const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP)

    return (
        <div style={{ maxHeight: '800px', overflowY: 'auto',marginTop:'50px' }}>
          <h3>Event History</h3>
            <ListGroup className="list-group-flush list-group-activity">
                    {sortedEvents?.length > 0 ?
                        sortedEvents?.map((item, index) => {
                            return (
                                <ListGroup.Item key={index}>
                                           <Row className="d-flex align-items-center">
                                        <Col className="col-auto fs-4">
                                         <div className="avatar avatar-sm">
                                            <div
                                             className={`avatar-title fs-lg badge bg-${
                                              item.event == 'delivered' ||  item.SmsStatus === 'delivered' ?
                                              getStatusBadgeColor("delivered") :
                                                   item.event == 'not_delivered' ||  item.SmsStatus === 'undelivered'?
                                              getStatusBadgeColor("undelivered") :
                                                 getIconBadgeColor(
                                                  selectedLogType,true
                                                )
                                              }
                                             } rounded-circle`} >
                                            {item.event === 'delivered' || item.SmsStatus === 'delivered' ? (
                                                <i className="fe fe-check-circle"></i>
                                            ) : item.event === 'not_delivered' || item.SmsStatus === 'undelivered' ? (
                                                <i className="fe fe-x"></i> 
                                            ) : (
                                                getBadgeIcons(selectedLogType, item)
                                            )}

                                           </div>
                                        </div>
                                        </Col>
                                        <Col xl={6}>
                                            <div className="">
                                                <span className='fw-bold'>
                                                {getBadgeStatus(selectedLogType,item)}
                                                </span>
                                            </div>
                                        </Col>
                                        <Col className="col-auto activity-margin" xl={4}>
                                            <div className='small'>
                                               {toLocalTime(item.timestamp,	myShipper?.shipper?.timezone?.id || myShipperGroup?.shipperGroup?.timezone?.id)}
                                             <br/>
                                            <div className="small text-muted">
                                               {toLocalDate(item.timestamp)}
                                            </div> 
                                            </div>
                                        </Col>
                                    </Row>
                                </ListGroup.Item>
                            );
                        })
                      : <p className="text-center">No data found</p>
                      }
             </ListGroup>
        </div>

    );
};