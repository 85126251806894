import { API } from 'aws-amplify';
import React, { useEffect, useState } from 'react';
import { Button, Card, Container, Dropdown, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { Link, useNavigate } from 'react-router-dom';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { PatientActiveFilter, TableKeywordSearch, TablePagination, TableShipperFilter, TableClearFilter, TableShipperGroupFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, isOwner } from '../../helpers';
import { storeShipper } from '../../stores/slice';

import ReactGA from "react-ga4"

const updateCustomerMutation = /* GraphQL */ `
  mutation UpdateCustomer(
    $input: UpdateCustomerInput!
    $condition: ModelCustomerConditionInput
  ) {
    updateCustomer(input: $input, condition: $condition) {
      id
	  name
	  active
    }
  }
`;


const CustomerList = () => {
	const dispatch = useDispatch();
	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/patient",
		})
	}, [])


	const navigate = useNavigate()

	const myShipper = useSelector((state) => state.slice.SHIPPER);
	const myShippers = useSelector((state) => state.slice.SHIPPERS);
	const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP);

	const [spinner, showSpinner] = useState(false);
	const [active, setActive] = useState('');
	const [customers, setCustomers] = useState([])
	const [keyword, setKeyword] = useState('')
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();

	useEffect(() => { getElasticCustomer() }, [myShipper, myShipperGroup, pageNumber, active]);

	useEffect(() => {
		const delay = setTimeout(() => { setPageNumber(0); getElasticCustomer(); }, DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword])

	const getElasticCustomer = async () => {
		showSpinner(true);
		try {
			const apiName = 'api';
			const path = `/search/customer?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;

			let init = {
				body: {
					sort: [
						{ "_score": { "order": "desc" } },
						{ "updatedAt": { "order": "desc", "unmapped_type": "date" } }
					],
					query: {
						bool: {
							should: [],
							must: []
						}
					}
				}
			}

			if (keyword) {
				delete init.body.sort
				let fields = [
					"extId",
					"firstName",
					"name",
					"lastName",
					"phone",
					"email"
				]
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (myShipperGroup?.shipperGroup?.id) init.body.query.bool.must.push({ match: { shipperGroupId: myShipperGroup?.shipperGroup?.id } })
			if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper?.id } })
			else if (!myShipperGroup?.shipperGroup?.id && myShippers) {
				let shipperIdArr = []
				myShippers.forEach((item) => {
					shipperIdArr.push(item.shipper.id)
				})
				init.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
			}

			if (active === true) init.body.query.bool.must.push({ match: { active: active } })
			else if (active === false) init.body.query.bool.must.push({ match: { active: active } })

			let data = await API.post(apiName, path, init);
			setCustomers(data.hits.hits);
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
			showSpinner(false);

		} catch (error) {
			showSpinner(false);
			console.error('error from  elastic search cutomer', error)
		}

	}

	const updateCustomer = (customer, status) => {
		showSpinner(true)
		API.graphql({ query: updateCustomerMutation, variables: { input: { id: customer?._source?.id, active: status } } })
			.then(async (response) => {
				setTimeout(() => {
					getElasticCustomer()
					showSpinner(false)
				}, 1000)
			}).catch((error) => {
				console.error(error);
				showSpinner(false)
			})
	}

	const clearFilters = () => {
		dispatch(storeShipper(myShippers))
		setActive('')
	}
	return (
		<>
			<PageHeader name='Patients'>
				{isOwner(myShipper?.role) && <Link to='/patient/create' className='btn btn-dark'>New Patient</Link>}
				<Button className='btn btn-light ms-2' onClick={() => window.location.reload()}>
					<i className='fe fe-refresh-cw'></i>
				</Button>
			</PageHeader>

			<Container fluid>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
							{myShipperGroup?.shipperGroup?.id && <TableShipperGroupFilter />}
							<TableShipperFilter hideAll={myShipperGroup?.shipperGroup?.id ? true : false} />
							<PatientActiveFilter active={active} onChange={setActive} />
							<TableClearFilter onClick={clearFilters} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm' className='mb-0' >
							<thead>
								<tr>
									<th>Name</th>
									<th>Location</th>
									<th>Email</th>
									<th>Phone</th>
									<th>Active</th>
								</tr>
							</thead>
							<tbody>
								{
									customers.map((customer) => {
										return (
											<tr key={customer?._source?.id} className={myShipper?.role !== 'VIEWER' && 'cursor-pointer'} onClick={() => myShipper?.role !== 'VIEWER' && navigate(`/patient/edit/${customer?._source?.id}`)}>
												<td>
													{customer?._source?.name}
													<div className='text-small text-muted'>{customer.alias}</div>
												</td>
												<td className='text-wrap'>
													{customer?._source?.address?.address1}
													<div className='small text-muted'>{customer?._source?.address?.city}, {customer?._source?.address?.state} {customer?._source?.address?.postalCode}</div>
												</td>
												<td className='text-wrap'>{customer?._source?.email}</td>
												<td className='text-wrap'>{customer?._source?.phone}</td>
												<td className='text-wrap' onClick={(e) => e.stopPropagation()} >
													<Dropdown>
														<Dropdown.Toggle variant='light' size='sm'>{(customer?._source?.active === true ? 'Active' : 'Inactive')}</Dropdown.Toggle>
														<Dropdown.Menu>
															<Dropdown.Item onClick={() => { updateCustomer(customer, true) }}>Active</Dropdown.Item>
															<Dropdown.Item onClick={() => { updateCustomer(customer, false) }}>Inactive</Dropdown.Item>
														</Dropdown.Menu>
													</Dropdown>
												</td>
											</tr>
										)
									})
								}
							</tbody>
							<TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={5} />
						</Table>
					</Spinner>
				</Card>
			</Container>
		</>
	);
};

export default CustomerList;
