import React from "react";

import { Col, ListGroup, Row } from "react-bootstrap";
import {
  getBadgeIcons,
  getIconBadgeColor,
  toLocalDate,
  toLocalTime,
} from "../helpers";
import {CommunicationStatusBadge} from './StatusBadge'
import { useSelector } from "react-redux";

const ListGroupIconItem = ({ items, onItemClick }) => {
  const myShipper = useSelector((state) => state.slice.SHIPPER);
    const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP)
  const handleClick = (item) => {
    onItemClick(item);
  };

  return (

    <ListGroup className="list-group-flush list-group-activity my-n3">
      {items &&
        items?.map((item, index) => {

          return (
            <ListGroup.Item key={index} onClick={() => handleClick(item)}>
              <Row className="justify-content-between cursor-pointer">
                  <Col className="col-auto" xl={1} xs={1}>
                  <div className="avatar avatar-sm">
                    <div
                      className={`avatar-title fs-lg badge bg-${getIconBadgeColor(
                        item.type
                      )} rounded-circle`}
                    >
                      {item.event === "delivered" ||
                      item.SmsStatus === "delivered" ? (
                        <i className="fe fe-check-circle"></i>
                      ) : (
                        getBadgeIcons(item.type, item)
                      )}
                    </div>
                  </div>
                  </Col>
                  <Col  xl={4} xs={4}>
                    <div>
                      To: {item.to} <br />
                      <span className="small text-muted">From: {item.from}</span>
                    </div>
                  </Col>
                  <Col  xl={3} xs={2}>
                    <div>
                      <span className="small">{item.subject}</span>
                    </div>
                  </Col>
                  <Col  xl={2} xs={2}>
                    <div className="small">
                      {toLocalTime(item.createdTime,myShipper?.shipper?.timezone?.id || myShipperGroup?.shipperGroup?.timezone?.id)} <br />
                      <div className="small text-muted">{toLocalDate(item.createdTime)}</div>
                  </div>
                  </Col>
                  <Col className="mt-2" xl={2} xs={2}>
                  <CommunicationStatusBadge status={item.status} />
                </Col>

                </Row>
            </ListGroup.Item>
          );
        })}
    </ListGroup>
);
};

export default ListGroupIconItem;
