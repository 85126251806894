import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Button, Col, Container, Form, FormGroup, ListGroup, Modal, Row } from 'react-bootstrap';
import Flatpickr from 'react-flatpickr';
import ReactGA from "react-ga4";
import toast from 'react-hot-toast';
import { useSelector } from 'react-redux';
import { useParams } from 'react-router-dom';
import { Card, StatCard } from "../../components/Card";
import FormLabel from '../../components/FormLabel';
import ListGroupItem from "../../components/ListGroupItem";
import Spinner from '../../components/Spinner';
import { BoxStatusBadge } from '../../components/StatusBadge';
import BoxStatus from '../../data/box-status.json';
import { PAGE_TITLE, boxStatusIcon, boxstatusBadgeColor, getShipperGroupsAndShippers, isViewer } from '../../helpers';
import BoxShipmentsTable from './BoxshipmentTable';



const getShipmentQuery = /* GraphQL */ `
  query GetShipment($id: ID!) {
    getShipment(id: $id) {
      id
      number
	  authCode
      shipFrom
      shipTo
      weight
      distance
      status
      services
      deliveryInstructions
      items
      billing
      attachment
	  packageCount
	  boxId
		box{
			number
            status
            name
            type
            size
            createdAt
            shipperId
            shipperGroupId
            shipmentId
            userId
            warehouseId
            warehouse {
                name
              }
		}
	  isGreenPhox
      shipperId
	  authCode
      createdTime

      expectedPickupTime
      actualPickupTime
      pickupLocation {
        latitude
        longitude
      }

      expectedDeliveryTime
      actualDeliveryTime
      deliveryLocation {
        latitude
        longitude
      }

      outForReturnTime
      returnedTime
      returnLocation {
        latitude
        longitude
      }

      extId
      extTrackingNumber
      extLabelUrl

      carrierId
      carrier {
        name
      }
	  route {
		routeSequence
		routeDistance
		routeDuration
	  }

      driverId
      driver {
        name
		location {
			latitude
			longitude
		}
      }
	  readyForPickupTime
      deliveryServiceId
      deliveryService {
        id
        type
        name
        pickupBy
        tatMin
        tatMax
        maxDistance
      }

      history {
        items {
          id
          createdTime
          createdAt
          status
          description
          attachment
          signature
          userId
          user {
            name
            image
          }
		  location{
			latitude
			longitude
		  }
        }
      }

      ratings {
        items {
          id
          rating
          nps
          feedback
        }
      }
    }
  }
`;

const boxStatusHistoriesByShipmentId = /* GraphQL */ `
  query BoxStatusHistoriesByShipmentId(
    $shipmentId: ID!
    $sortDirection: ModelSortDirection
    $filter: ModelBoxStatusHistoryFilterInput
    $limit: Int
    $nextToken: String
  ) {
    boxStatusHistoriesByShipmentId(
      shipmentId: $shipmentId
      sortDirection: $sortDirection
      filter: $filter
      limit: $limit
      nextToken: $nextToken
    ) {
      items {
        id
        boxId
        userId
        user{
            name
        }
        shipmentId
        shipment{
            shipTo
            shipFrom
            shipper{
                name
                timezone{
                    id
                }
            }
        }
        status
        description
        attachment
        signature
        createdAt
        createdTime
        updatedAt
        __typename
      }
      nextToken
      __typename
    }
  }
`;

const updateShipmentBoxMutation = /* GraphQL */ `
  mutation UpdateShipmentBox(
    $input: UpdateShipmentBoxInput!
  ) {
    updateShipmentBox(input: $input) {
      id
    }
  }
`;

const createBoxStatusHistory = /* GraphQL */ `
mutation CreateBoxStatusHistory(
  $input: CreateBoxStatusHistoryInput!
  $condition: ModelBoxStatusHistoryConditionInput
) {
  createBoxStatusHistory(input: $input, condition: $condition) {
	id
	boxId
	userId
	shipmentId
  }
}
`;

const getShipmentBox = /* GraphQL */ `
  query GetShipmentBox($id: ID!) {
    getShipmentBox(id: $id) {
      id
      shipperId
      shipper {
        id
        name
        alias
      }
      shipmentId
      assignedAt
      number
      name
      shipments {
        items{
            id
            distance
            shipTo
            status
            number
        }
        nextToken
        __typename
      }
      createdAt
      updatedAt
    }
  }
`;

const listWarehouses = /* GraphQL */ `
  query ListWarehouses($limit: Int) {
    listWarehouses( limit: $limit) {
      items {
        id
        name
        alias
        phone
        email
      }
    }
  }
`;

const BoxDetails = () => {

    const myUser = useSelector(state => state.slice.USER);
    const { shipmentid } = useParams()
    const [spinner, showSpinner] = useState(false);
    const [shipment, setShipment] = useState({});
    const [boxCardData, setBoxCardData] = useState({})
    const [boxStatusHistory, setBoxStatusHistory] = useState([]);



    useEffect(() => {
        ReactGA.send({
            hitType: "pageview",
            page: `/box/details/${shipmentid}`,
        })
        document.title = `Green Phox Box Details| ${PAGE_TITLE}`
    }, [shipment]);

    useEffect(() => { getShipment(); getBoxStatusHistory() }, [shipmentid])



    const getBoxDetails = async (boxId) => {
        try {
            const { data } = await API.graphql({ query: getShipmentBox, variables: { id: boxId } })
            let deliveries = 0
            let savings = 0;
            let mileage = 0;
            let lifeSpan = Math.floor(moment().diff(moment(data.getShipmentBox?.createdAt), 'minutes') / (24 * 60))


            data.getShipmentBox?.shipments?.items.forEach((item) => {
                if (item?.status === "DELIVERED") {
                    savings += 1
                    deliveries += 1
                }
                if (item?.status === "DELIVERED" || item?.status === "RETURNED") {
                    mileage += item?.distance
                }
            });


            let locationData = [];

            data.getShipmentBox?.shipments?.items?.map((item) => {
                let locations = JSON.parse(item.shipTo)
                locationData.push(locations?.address?.location)
            })

            setBoxCardData({
                deliveries,
                lifeSpan,
                locationData,
                savings: (savings * 29.79).toFixed(2),
                mileage: mileage * 2
            })
        } catch (error) {
            console.error("Box Data error", error);
        }

    }

    const getBoxStatusHistory = () => {
        showSpinner(true)
        API.graphql({ query: boxStatusHistoriesByShipmentId, variables: { shipmentId: shipmentid } }).then((response) => {
            setBoxStatusHistory(response?.data?.boxStatusHistoriesByShipmentId?.items)
        }).catch((error) => {
            console.error(error)
        }).finally(() => showSpinner(false))
    }

    function getShipment() {
        showSpinner(true)
        API.graphql({ query: getShipmentQuery, variables: { id: shipmentid } }).then((response) => {
            let data = response?.data?.getShipment
            data.shipFrom = JSON.parse(data?.shipFrom)
            data.shipTo = JSON.parse(data?.shipTo)
            setShipment(data)
            getBoxDetails(data?.boxId)
            showSpinner(false)
            return data;
        }).catch((error) => {
            console.error(error)
            toast.error('Opps, something went wrong')
            showSpinner(false)
        })
    }

    const BoxCard = () => {
        const [boxModal, showBoxModal] = useState(false);
        const [boxStatusHistoryData, setBoxStatusHistoryData] = useState({ status: shipment?.box?.status, shipperId: shipment?.box?.shipperId, shipperGroupId: shipment?.box?.shipperGroupId, warehouseId: shipment?.box?.warehouseId });
        const [statusDate, setStatusDate] = useState(new Date());
        const [spinner, showSpinner] = useState(false);




        const UpdateBoxStatus = () => {
            const inputDate = moment(statusDate).format('YYYY-MM-DD HH:mm')
            const tzDateTime = moment.tz(inputDate, shipment?.shipFrom?.timezone?.id).unix()
            let boxStatus = boxStatusHistoryData?.status
            if (!boxStatus) {
                toast.error('Box status is Required')
            } else {
                showSpinner(true)
                let boxInput = {
                    id: shipment?.boxId,
                    status: boxStatus,
                    shipperGroupId: boxStatusHistoryData?.shipperGroupId,
                    shipperId: boxStatusHistoryData?.shipperId,
                    warehouseId: boxStatusHistoryData?.warehouseId,
                }

                if (boxStatusHistoryData?.warehouseId) {
                    boxInput.userId = null;
                }

                if (boxStatus === "RETIRED") {
                    boxInput.retiredAt = moment().unix();
                }

                let statusArray = ['RETURNED', 'AVAILABLE']
                if (statusArray?.includes(boxStatus)) {
                    boxInput.userId = null;
                    boxInput.carrierId = null;
                    boxInput.shipmentId = null;
                }

                if (["RETIRED", "DAMAGED"].includes(boxStatus)) {
                    boxInput.shipperGroupId = null
                    boxInput.shipperId = null
                    boxInput.carrierId = null
                    boxInput.userId = null
                }

                let historyInput = {
                    boxId: shipment?.boxId,
                    shipmentId: shipment?.id,
                    userId: myUser?.id,
                    status: boxStatus,
                    createdTime: tzDateTime,
                    description: boxStatusHistoryData?.description
                }

                let task = []
                task.push(API.graphql({ query: updateShipmentBoxMutation, variables: { input: boxInput } }))

                let statusArray2 = ['IN_USE', 'OUT_FOR_RETURN', 'RETURNED'];
                statusArray2?.includes(boxStatus) && task.push(API.graphql({ query: createBoxStatusHistory, variables: { input: historyInput } }))

                Promise.all(task).then((response) => {
                    toast.success('Box status has been updated')
                    setBoxStatusHistoryData()
                    getBoxStatusHistory()
                    getShipment()
                }).catch((error) => {
                    toast.error('Opps,something went wrong');
                    console.error(error);
                }).finally(() => { showSpinner(false); showBoxModal(false) })
            }

        }


        return (
            <>
                <Card className='mb-0' title="Box Details" bodyPadding="py-0" buttonName={!isViewer(myUser.role) ? 'Update Status' : ''} onClick={() => showBoxModal(true)} >
                    <ListGroup className="list-group-flush">
                        <ListGroupItem name="Box Version" value={shipment?.box?.type} />
                        <ListGroupItem name="Box Size" value={shipment?.box?.size} />
                        <ListGroupItem name="Box Created Date" value={moment(shipment?.box?.createdAt)?.tz(shipment?.shipFrom?.timezone?.id)?.format("hh:ss a z, MMM DD,YYYY")} />
                        <ListGroupItem name="Box Retired Date" value={(shipment?.box?.status === 'RETIRED' && moment(shipment?.box?.retiredAt)?.tz(shipment?.shipFrom?.timezone?.id)?.format("hh:ss a z, MMM DD,YYYY") || '-')} />
                    </ListGroup>
                </Card>

                <Modal Modal show={boxModal} onHide={() => showBoxModal(false)}>
                    <Modal.Header>
                        <h4>Update Box Status</h4>
                    </Modal.Header>
                    <Modal.Body>

                        <FormGroup>
                            <FormLabel> Box Status</FormLabel>
                            <Form.Select value={boxStatusHistoryData?.status} onChange={(e) => setBoxStatusHistoryData({ ...boxStatusHistoryData, status: e.target.value, warehouseId: null, description: "" })}>
                                <option value={''}>Select status</option>
                                {Object.keys(BoxStatus).map((item, index) => <option key={index} value={item}>{BoxStatus[item]}</option>)}
                            </Form.Select>
                        </FormGroup>


                        <FormGroup className='my-2' >
                            <FormLabel> Description</FormLabel>
                            <textarea key={boxStatusHistoryData?.warehouseId} value={boxStatusHistoryData?.description} className='form-control' rows={2} onChange={(e) => setBoxStatusHistoryData({ ...boxStatusHistoryData, description: e.target.value })} />
                        </FormGroup>

                        {
                            ['IN_USE', 'OUT_FOR_RETURN', 'RETURNED'].includes(boxStatusHistoryData?.status) && <>
                                <FormLabel required>
                                    {boxStatusHistoryData?.status === 'IN_USE' && 'Actual In Use Time'}
                                    {boxStatusHistoryData?.status === 'OUT_FOR_DELIVERY' && 'Actual Pickup Time'}
                                    {boxStatusHistoryData?.status === 'DELIVERED' && 'Actual Delivery Time'}
                                    {boxStatusHistoryData?.status === 'OUT_FOR_RETURN' && 'Out for Return Time'}
                                    {boxStatusHistoryData?.status === 'RETURNED' && 'Returned Time'}
                                </FormLabel>
                                <div className='mb-3'>
                                    <Flatpickr className='form-control d-block' name='statusDate'
                                        value={statusDate}
                                        options={{ enableTime: true, dateFormat: 'M d, Y h:i K', static: true }}
                                        onChange={(dates) => setStatusDate(new Date(dates[0]))}
                                    />
                                </div>
                            </>
                        }


                    </Modal.Body>
                    <Modal.Footer className='display-flex-start'>
                        <Spinner display={spinner}>
                            <Button className='btn btn-dark' onClick={() => UpdateBoxStatus()}>Update</Button>
                            <Button className='btn btn-light' onClick={() => { setBoxStatusHistoryData({ status: shipment?.box?.status, shipperId: shipment?.box?.shipperId, shipperGroupId: shipment?.box?.shipperGroupId }); showBoxModal(false) }} >Cancel</Button>
                        </Spinner>
                    </Modal.Footer>
                </Modal >
            </>
        )
    }

    const BoxPageHeader = () => {
        return (

            <nav className="header mb-0">
                <Container fluid>
                    <div className="header-body">
                        <Row className="align-items-center">
                            <Col className="d-flex">
                                <div>
                                    <h1 className="mb-1">
                                        {`#${shipment?.box?.number || shipment?.box?.name}`}
                                    </h1>
                                    <h2 className="text-muted mb-1">
                                        {shipment?.shipFrom?.name || "-"}
                                    </h2>
                                </div>
                                <div className="align-item-centers">
                                    < img src="/img/greenphox.svg" alt="green-phox-icon rounded-circle" className="mx-2" height={'50px'} width={'50px'} />
                                </div>
                            </Col>
                        </Row>
                    </div>
                </Container>

            </nav >
        )

    }

    const CarrierDriver = () => {
        return (
            <>
                <Card
                    title="Courier Details"
                    bodyPadding="py-0"
                >
                    <ListGroup className="list-group-flush">
                        <ListGroupItem name="Courier" value={shipment?.carrier?.name || '-'} />
                        <ListGroupItem name="Driver" value={shipment?.driver?.name || '-'} />
                    </ListGroup>
                </Card>
            </>
        )
    }


    const BoxHistory = () => {

        let expectedReturnTime = moment.unix(shipment?.expectedDeliveryTime).add(1, 'day').format('MMM DD, YYYY')
        let boxReturnedAt = ''
        boxStatusHistory?.map((history) => {
            expectedReturnTime = moment.unix(shipment?.expectedDeliveryTime).tz(history?.shipment?.shipper?.timezone?.id).add(1, 'day').format('h:mm a z, MMM DD, YYYY')
            if (history.status === 'RETURNED') {
                boxReturnedAt = moment.unix(history?.createdTime).tz(history?.shipment?.shipper?.timezone?.id).format('h:mm a z, MMM DD, YYYY');
            }
        })

        return (
            <>
                <Card title={<BoxStatusBadge status={shipment?.box?.status} />} moreDetails={(!["RETIRED", "DAMAGED", "AVAILABLE"].includes(shipment?.box?.status) && (shipment?.box?.status === "RETURNED" ? `Returned At: ${boxReturnedAt}` : `Expected Return: ${expectedReturnTime}`))} >
                    <ListGroupItem name="Shipment Number" value={shipment?.number} url={`/shipment/${shipment?.id}`} />
                    <hr />
                    <ListGroup className="list-group-flush list-group-activity my-n3">
                        {boxStatusHistory &&
                            boxStatusHistory?.sort((a, b) => b?.createdTime - a?.createdTime)?.map((item, index) => {
                                return (
                                    <ListGroup.Item key={index}>
                                        <Row>
                                            <Col className="col-auto">
                                                <div className="avatar avatar-sm">
                                                    <div
                                                        className={`avatar-title fs-lg badge bg-${boxstatusBadgeColor(item?.status)} rounded-circle`} >
                                                        <i className={boxStatusIcon(item?.status)}></i>
                                                    </div>
                                                </div>
                                            </Col>
                                            <Col className="activity-alignment" >
                                                {BoxStatus[item?.status]}
                                                <div className="small text-muted">
                                                    <div>{item.description}</div>
                                                    {item?.user?.name && <div className="mt-2">
                                                        <i className="fe fe-user" />{" "}
                                                        {item?.user?.name}
                                                    </div>}
                                                </div>
                                            </Col>
                                            <Col className="col-auto activity-alignment activity-margin">
                                                <div className="small ">
                                                    {moment(item?.createdAt)?.tz(item?.shipment?.shipper?.timezone?.id)?.format('h:mm a z')}
                                                </div>
                                                <div className="small text-muted ">
                                                    {moment(item?.createdAt)?.tz(item?.shipment?.shipper?.timezone?.id)?.format('MMM DD, YYYY')}
                                                </div>
                                            </Col>
                                        </Row>
                                    </ListGroup.Item>
                                );
                            })}
                    </ListGroup>
                </Card>
            </>
        );
    };



    return (
        <>
            <Spinner display={spinner} >
                <BoxPageHeader />
                <Container fluid className="mt-5">
                    <Row>
                        <StatCard title='Deliveries' value={!isNaN(boxCardData?.deliveries) ? boxCardData?.deliveries : 0} icon='check-circle' />
                        <StatCard title='Lifespan' value={!isNaN(boxCardData?.lifeSpan) ? `${boxCardData?.lifeSpan} days` : 0} icon='calendar' />
                        <StatCard title='Savings' value={!isNaN(boxCardData?.savings) ? `$ ${boxCardData?.savings} ` : 0} icon='dollar-sign' />
                        <StatCard title='Mileage' value={!isNaN(boxCardData?.mileage) ? boxCardData?.mileage : 0} icon='repeat' />
                    </Row>
                    <Row>
                        <Col lg={6}>
                            <BoxCard />
                            <CarrierDriver />
                        </Col>
                        <Col lg={6}>
                            <BoxHistory />
                        </Col>
                        <Col >
                            {shipment &&
                                <BoxShipmentsTable shipment={shipment} />
                            }
                        </Col>

                    </Row>
                </Container>


            </Spinner>


        </>
    )
}

export default BoxDetails
