/* eslint-disable react-hooks/exhaustive-deps */
import { API } from 'aws-amplify';
import moment from 'moment';
import React, { useEffect, useState } from 'react';
import { Card, Col, Container, Row, Table } from 'react-bootstrap';
import { useDispatch, useSelector } from 'react-redux';
import { useNavigate } from 'react-router-dom';
import StarRatings from 'react-star-ratings';
import { StatCard } from '../../components/Card';
import { ElasticFacetFilter } from '../../components/ElasticFilter';
import PageHeader from '../../components/PageHeader';
import Spinner from '../../components/Spinner';
import { TableDateFilter, TableKeywordSearch, TablePagination, TableShipperFilter, TableClearFilter, TimeZoneFilter, TableShipperGroupFilter } from '../../components/TableFilter';
import { DELAY_TIMEOUT, PAGE_SIZE, toLocalDate, toLocalTime } from '../../helpers';
import { storeShipper } from '../../stores/slice';

import ReactGA from "react-ga4"
import CardHeader from 'react-bootstrap/esm/CardHeader';
import { DoughnutChart } from '../../components/Chart';

const RatingDashboard = () => {

	useEffect(() => {
		ReactGA.send({
			hitType: "pageview",
			page: "/feedback",
		})
	}, [])

	const dispatch = useDispatch();
	const navigate = useNavigate();

	const myShipper = useSelector((state) => state.slice.SHIPPER);
	const myShippers = useSelector((state) => state.slice.SHIPPERS);
	const myShipperGroup = useSelector((state) => state.slice.SHIPPER_GROUP);

	const [spinner, showSpinner] = useState(false);
	const [ratings, setRatings] = useState([])
	const [resetDate, setRestDate] = useState(0);
	const [carrier, setCarrier] = useState()
	const [facets, setFacets] = useState({})
	const [keyword, setKeyword] = useState('')
	const [pageNumber, setPageNumber] = useState(0);
	const [pageCount, setPageCount] = useState();
	const [ratingAverage, setRatingAverage] = useState(0)
	const [npsAverage, setNpsAverage] = useState(0)
	const [timeZonee, setTimeZone] = useState("America/New_York");
	const [tz, setTz] = useState("EST");
	const [shipperRating, setShipperRating] = useState([]);
	const [carrierRating, setCarrierRating] = useState([]);
	const [responseAverage, setResponseAverage] = useState(0);
	const [totalRating, setTotalRating] = useState({ totalRatingCount: 0, rating: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } });
	const [npsRating, setNpsRating] = useState([
		{ label: 'Promoters', color: '#00A76F', percentage: 0, count: 0 },
		{ label: 'Passives', color: '#FEC203', percentage: 0, count: 0 },
		{ label: 'Detractors', color: '#E84521', percentage: 0, count: 0 },
	])

	const [dateFilters, setDateFilters] = useState({
		fromDate: moment().tz(timeZonee || 'America/New_York')?.subtract('days').startOf('day').unix(),
		toDate: moment().tz(timeZonee || 'America/New_York')?.endOf('day').unix()
	});
	useEffect(() => {
		if (myShipperGroup?.shipperGroup?.timezone?.id) {
			setTimeZone(myShipperGroup?.shipperGroup?.timezone?.id)
			setTz(myShipperGroup?.shipperGroup?.timezone?.alias)
		} else if (myShipper?.shipper?.timezone?.id) {
			setTimeZone(myShipper?.shipper?.timezone?.id)
			setTz(myShipper?.shipper?.timezone?.alias)
		}
		if (keyword) setPageCount(0)
		const delay = setTimeout(() => { getElasticRatings(); }, DELAY_TIMEOUT)
		return () => clearTimeout(delay)
	}, [keyword, dateFilters, myShipper, pageNumber, carrier, myShippers, myShipperGroup])

	function caluclateAverageResponseRate(totalShipments, totalNPSCount, totalRatingCount) {
		let avg_response_rate;
		if (totalShipments != null && totalRatingCount > totalNPSCount) {
			avg_response_rate = (totalRatingCount / totalShipments) * 100;
		}
		else if (totalNPSCount != null) {
			avg_response_rate = (totalNPSCount / totalShipments) * 100;
		} else {
			avg_response_rate = 0
		}
		return avg_response_rate?.toFixed(2)
	}

	const getElasticRatings = async () => {
		showSpinner(true)
		try {
			const apiName = 'api';
			const path = `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`;
			let init = {
				body: {
					"aggs": {
						"carrier": {
							"terms": {
								"field": "carrier.name.keyword",
								"size": 1000,
								"order": {
									"avg_rating": "desc"
								}
							},
							"aggs": {
								"avg_rating": {
									"avg": {
										"field": "ratings.items.rating"
									}
								}
							}
						},
						"shipper": {
							"terms": {
								"field": "shipper.name.keyword",
								"size": 1000,
								"order": {
									"avg_rating": "desc"
								}
							},
							"aggs": {
								"shipper_id": {
									"top_hits": {
										"_source": ["shipper.id"],
										"size": 1
									}
								},
								"avg_rating": {
									"avg": {
										"field": "ratings.items.rating"
									}
								},
								"avg_nps": {
									"scripted_metric": {
										"init_script": "state.promoters = 0; state.detractors = 0; state.total = 0;",
										"map_script": `
											if (doc['ratings.items.nps'].size() > 0) {
											  def nps = doc['ratings.items.nps'].value;
											  if (nps >= 9) {
												state.promoters += 1;
											  } else if (nps <= 6) {
												state.detractors += 1;
											  }
											  state.total += 1;
											}
										  `,
										"combine_script": "return state;",
										"reduce_script": `
											int promoters = 0;
											int detractors = 0;
											int total = 0;
											
											for (s in states) {
											  promoters += s.promoters;
											  detractors += s.detractors;
											  total += s.total;
											}
											
											if (total > 0) {
											  return Math.ceil(((promoters - detractors) / (double) total) * 100);
											} else {
											  return 0;
											}
										  `
									}
								},
								"total_ratings_count": {
									"value_count": {
										"field": "ratings.items.rating"
									}
								},
								"total_nps_count": {
									"value_count": {
										"field": "ratings.items.nps"
									}
								},
							}
						},
						"avg_rating": {
							"avg": {
								"field": "ratings.items.rating"
							}
						},
						"nps_stats": {
							"scripted_metric": {
								"init_script": "state.promoters = 0; state.passives = 0; state.detractors = 0; state.total = 0;",
								"map_script": `
									if (doc.containsKey('ratings.items.nps') && doc['ratings.items.nps'].size() > 0) {
										def nps = doc['ratings.items.nps'].value;
										state.total += 1;
										
										if (nps > 8) {
											state.promoters += 1;
										} else if (nps >= 7 && nps <= 8) {
											state.passives += 1;
										} else {
											state.detractors += 1;
										}
									}
								`,
								"combine_script": "return state;",
								"reduce_script": `
								int promoters = 0;
								int passives = 0;
								int detractors = 0;
								int total = 0;

								// Aggregate values across all shards
								for (s in states) {
									promoters += s.promoters;
									passives += s.passives;
									detractors += s.detractors;
									total += s.total;
								}

								// Calculate percentages if total ratings are available
								if (total > 0) {
									double promotersPercentage = (promoters / (double) total) * 100;
									double passivesPercentage = (passives / (double) total) * 100;
									double detractorsPercentage = (detractors / (double) total) * 100;

									double npsScore = Math.ceil(((promoters - detractors) / (double) total) * 100);

									return [
										"promoters": promoters,
										"promotersPercentage": promotersPercentage,
										"passives": passives,
										"passivesPercentage": passivesPercentage,
										"detractors": detractors,
										"detractorsPercentage": detractorsPercentage,
										"npsScore": npsScore,
										"total": total
									];
								} else {
									return [
										"promoters": promoters,
										"promotersPercentage": 0,
										"passives": passives,
										"passivesPercentage": 0,
										"detractors": detractors,
										"detractorsPercentage": 0,
										"npsScore": 0,
										"total": total
									];
								}
							`
							}
						},
						"total_ratings_count": {
							"value_count": {
								"field": "ratings.items.rating"
							}
						},
						"total_nps_count": {
							"value_count": {
								"field": "ratings.items.nps"
							}
						},
						"ratings_by_star": {
							"terms": {
								"field": "ratings.items.rating",
								"size": 5,
								"order": { "_key": "asc" }
							},
							"aggs": {
								"rating_count": {
									"value_count": {
										"field": "ratings.items.rating"
									}
								}
							}

						},
					},
					sort: [
						{
							"createdTime": {
								"order": "desc",
								"unmapped_type": "date"
							}
						}
					],
					query: {
						bool: {
							must: [
								{
									match: { status: "DELIVERED" }
								},
								{
									"exists": {
										"field": "ratings.items.feedback"
									}
								}
							]
						}
					}
				}
			}

			if (!keyword) {
				init.body.query.bool.must.push({
					range: {
						"actualDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {
				delete init.body.sort

				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("shipment.number")
				}
				init.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (myShipperGroup?.shipperGroup?.id) init.body.query.bool.must.push({ match: { shipperGroupId: myShipperGroup?.shipperGroup?.id } })
			if (myShipper?.shipper?.id) init.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper.id } })
			else if (!myShipperGroup?.shipperGroup?.id && myShippers) {
				let shipperIdArr = []
				myShippers.forEach((item) => {
					shipperIdArr.push(item.shipper.id)
				})
				init.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
			}
			if (carrier) init.body.query.bool.must.push({ match: { "carrier.name": carrier } })
			let data = await API.post(apiName, path, init);

			setRatings(data?.hits?.hits)
			let aggregationsCopy = JSON.parse(JSON.stringify(data?.aggregations));
			setFacets(aggregationsCopy)
			setPageCount(Math.ceil(data?.hits?.total?.value / PAGE_SIZE));
			showSpinner(false)

			if (data?.aggregations?.carrier?.buckets?.length) {
				let filteredData = data?.aggregations?.carrier?.buckets?.filter((item) => item?.avg_rating?.value !== null && item)
				setCarrierRating(filteredData)
			}
			else setCarrierRating([]);

			if (data?.aggregations?.nps_stats) {
				let nps_stats = data?.aggregations?.nps_stats?.value
				let avg_nps = nps_stats?.npsScore ? Math.round(nps_stats?.npsScore) : 0
				let ratings = [
					{ label: 'Promoters', color: '#00A76F', percentage: nps_stats?.promotersPercentage, count: nps_stats?.promoters },
					{ label: 'Passives', color: '#FEC203', percentage: nps_stats?.passivesPercentage, count: nps_stats?.passives },
					{ label: 'Detractors', color: '#E84521', percentage: nps_stats?.detractorsPercentage, count: nps_stats?.detractors }
				]
				setNpsRating(ratings)
				setNpsAverage(avg_nps)
			} else {
				setNpsAverage(0);
				setNpsRating([
					{ label: 'Promoters', color: '#00A76F', percentage: 0, count: 0 },
					{ label: 'Passives', color: '#FEC203', percentage: 0, count: 0 },
					{ label: 'Detractors', color: '#E84521', percentage: 0, count: 0 },
				])
			}



			if (data?.aggregations?.ratings_by_star?.buckets?.length) {
				let ratingByStar = { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 }
				let totalRatingCount = data?.aggregations?.total_ratings_count?.value
				for (let item of data?.aggregations?.ratings_by_star?.buckets) {
					ratingByStar[item?.key] += item?.rating_count?.value;
				}
				setTotalRating({ totalRatingCount: totalRatingCount, rating: ratingByStar })
			} else {
				setTotalRating({ totalRatingCount: 0, rating: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } })
			}

			if (data?.aggregations?.avg_rating?.value) {
				setRatingAverage(data?.aggregations?.avg_rating?.value)
			} else {
				setRatingAverage(0)
			}

			//*** get delivered data ***//
			const shipmentInit = {
				body: {
					query: {
						bool: {
							must: [
								{
									match: { status: "DELIVERED" }
								}
							]
						}
					}
				}

			}

			if (!keyword) {
				shipmentInit.body.query.bool.must.push({
					range: {
						"actualDeliveryTime": {
							"gte": dateFilters?.fromDate,
							"lte": dateFilters?.toDate
						}
					}
				})
			}

			if (keyword) {

				let fields = [
					"shipTo.name^3",
					"shipTo.first_name^2",
					"shipTo.last_name",
					"shipTo.phone",
					"customer.extId",
					"extId"
				]
				if (+keyword) {
					fields.push("number")
				}
				shipmentInit.body.query.bool.must.push(
					{
						"multi_match": {
							"query": keyword,
							"fields": fields
						}
					}
				)
			}

			if (myShipperGroup?.shipperGroup?.id) shipmentInit.body.query.bool.must.push({ match: { shipperGroupId: myShipperGroup?.shipperGroup?.id } })
			if (myShipper?.shipper?.id) shipmentInit.body.query.bool.must.push({ match: { shipperId: myShipper?.shipper.id } })
			else if (!myShipperGroup?.shipperGroup?.id && myShippers) {
				let shipperIdArr = []
				myShippers.forEach((item) => {
					shipperIdArr.push(item.shipper.id)
				})
				shipmentInit.body.query.bool.must.push({ "terms": { "shipperId": shipperIdArr } })
			}
			if (carrier) shipmentInit.body.query.bool.must.push({ match: { "carrier.name": carrier } })

			let deliveredData = await API.post(apiName, `/search/shipment?size=${PAGE_SIZE}&from=${pageNumber * PAGE_SIZE}`, shipmentInit);

			if (deliveredData?.hits?.total?.value) {
				let avg_response_rate = caluclateAverageResponseRate(deliveredData?.hits?.total?.value, data?.aggregations?.total_nps_count?.value, data?.aggregations?.total_ratings_count?.value)
				setResponseAverage(avg_response_rate)
			} else setResponseAverage(0)

			if (data?.aggregations?.shipper?.buckets?.length && deliveredData?.hits?.hits?.length) {
				for (let item of data?.aggregations?.shipper?.buckets) {
					let total_shipments_count = 0;
					for (let hit of deliveredData?.hits?.hits) {
						if (item.key === hit._source?.shipper?.name) {
							total_shipments_count += 1
						}
					}
					item.total_shipments_count = total_shipments_count;
					let avg_response_rate = caluclateAverageResponseRate(total_shipments_count, item.total_nps_count?.value, item.total_ratings_count?.value)
					item.avg_response_rate = avg_response_rate
				}
				setShipperRating(data?.aggregations?.shipper?.buckets)
			} else setShipperRating([])



		} catch (error) {
			showSpinner(false)
			console.error("error from elastic search Feedback", error)
		}

	}
	const clearFilters = () => {
		setRestDate(prev => prev === 0 ? prev = 1 : prev = 0)
		setDateFilters({
			fromDate: moment().tz('America/New_York')?.subtract('days').startOf('day').unix(),
			toDate: moment().tz('America/New_York')?.endOf('day').unix()
		});
		getElasticRatings()
		setCarrier(null)
		setTimeZone("America/New_York");
		setTz("EST");
		dispatch(storeShipper(myShippers))
		setNpsRating([
			{ label: 'Promoters', color: '#00A76F', percentage: 0, count: 0 },
			{ label: 'Passives', color: '#FEC203', percentage: 0, count: 0 },
			{ label: 'Detractors', color: '#E84521', percentage: 0, count: 0 },
		]);
		setTotalRating({ totalRatingCount: 0, rating: { 1: 0, 2: 0, 3: 0, 4: 0, 5: 0 } })
		setShipperRating([]);
		setResponseAverage(0);
		setCarrierRating([])
	}
	const tzHandle = (e) => {
		setTimeZone(e);
	};


	const calculatePercentage = (count) => count === 0 ? 0 : (count / totalRating?.totalRatingCount) * 100;

	return (
		<>
			<PageHeader name='StarPhox'>
				<Row>
					{myShipperGroup?.shipperGroup?.id && <TableShipperGroupFilter />}
					<TableShipperFilter hideAll={myShipperGroup?.shipperGroup?.id ? true : false} />
					<ElasticFacetFilter name={'Courier'} item={carrier} facetName={'carrier'} facets={facets} onChange={setCarrier} />
					<TableDateFilter key={resetDate} timezone={myShipper?.shipper?.timezone?.id} onChange={setDateFilters} startOf={0} />
					<TimeZoneFilter
						title={""}
						setTimeZone={setTimeZone}
						dark={true}
						onChange={tzHandle}
						tz={tz}
						setTz={setTz}
					/>
					<TableClearFilter onClick={clearFilters} />

				</Row>
			</PageHeader>

			<Container fluid>
				<Row>
					<StatCard title='Average Rating' value={ratingAverage?.toFixed(2) ?? '-'} icon='star' />
					<StatCard title='Average NPS' value={npsAverage ?? '-'} icon='thumbs-up' />
					<StatCard title='Response Rate' value={responseAverage ? responseAverage : '-'} icon='percent' />
				</Row>
				<Card>
					<Row className="gy-4" md={1} lg={2} >
						<Col className='p-5 border-end'>
							<div className="rating-summary">
								<div className="d-flex align-items-center mb-3">
									<div className='mb-2 me-2'>
										<StarRatings rating={ratingAverage || 0} starRatedColor='#FFCD3C' starDimension='18px' starSpacing='1px' />
									</div>
									<span>Based on {totalRating?.totalRatingCount} Reviews</span>
								</div>

								{Object.entries(totalRating?.rating).map(([star, count]) => (
									<div key={star} className="d-flex align-items-center justify-content-between mb-2">

										<span className="me-2">{star} <span className="text-warning">&#9733;</span></span>
										<div className="progress flex-grow-1 me-2" style={{ height: '8px' }}>
											<div
												className="progress-bar bg-warning"
												role="progressbar"
												style={{ width: `${calculatePercentage(count)}%` }}
												aria-valuenow={calculatePercentage(count)}
												aria-valuemin="0"
												aria-valuemax="100"
											></div>
										</div>
										<span>{count}</span>
									</div>
								))}
							</div>
						</Col>
						<Col>
							{(npsRating[0]?.count || npsRating[1]?.count || npsRating[2]?.count) ?
								<Row md={1} lg={2} className='py-3 text-center'>
									<Col className="chart-container py-3" >
										<DoughnutChart
											backgroundColor={["#00A76F", "#FEC203", "#E84521"]}
											data={{
												labels: ['Promoters', 'Passives', 'Detractors'],
												datasets: [npsRating[0]?.count, npsRating[1]?.count, npsRating[2]?.count],
											}}
											height={200}
											hideLabel={true}
										/>
									</Col>
									<Col className="nps-summary d-flex flex-column justify-content-center mt-5">
										{npsRating?.map((item) => (
											<div key={item.label} className="d-flex align-items-center justify-content-center justify-content-lg-start mb-2">
												<span
													className="me-2"
													style={{
														width: '12px',
														height: '12px',
														backgroundColor: item?.color,
														borderRadius: '50%',
														display: 'inline-block',
													}}
												></span>
												<span className="me-2">{item?.label}</span>
												<span className="fw-bold me-2">{item?.percentage?.toFixed(2)}%</span>
												<span className="text-muted">({item?.count})</span>
											</div>
										))}
									</Col>
								</Row> :
								<div className='d-flex align-items-center justify-content-center h-100 text-muted text-sm'>No NPS data found</div>
							}
						</Col>
					</Row>

				</Card>
				<Row>
					<Col md={12} lg={8}>
						<Card>
							<CardHeader>
								Ratings by Shipper
							</CardHeader>
							<Spinner display={spinner}>
								<Table responsive size='sm' className='mb-0' >
									<thead>
										<tr>
											<th className='text-left'>Shipper</th>
											<th className='text-center'>Avg Rating</th>
											<th className='text-center'>Avg Nps</th>
											<th className='text-center'>Avg Response Rate</th>
										</tr>
									</thead>
									<tbody>
										{shipperRating?.map((rating) => {
											return (
												<>
													< tr key={rating?.key}>
														<td className='text-left'>{rating.key}</td>
														<td className='text-center'>{rating?.avg_rating?.value ? <><span className="text-warning"> &#9733; </span>{rating?.avg_rating?.value.toFixed(2)}</> : "-"}</td>
														<td className='text-center'>{rating?.avg_nps?.value ? rating?.avg_nps?.value : 0}</td>
														<td className='text-center'>{rating?.avg_response_rate}</td>
													</tr >

												</>
											)
										})
										}
									</tbody>
								</Table>
							</Spinner>
						</Card>
					</Col>
					<Col md={12} lg={4}>
						<Card>
							<CardHeader>
								Ratings by Courier
							</CardHeader>
							<Spinner display={spinner}>
								<Table responsive size='sm' className='mb-0' >
									<thead>
										<tr>
											<th className='text-start'>#Rank</th>
											<th className='text-start'>Courier</th>
											<th className='text-center'>Average Star Rating</th>
										</tr>
									</thead>
									<tbody>
										{carrierRating?.map((rating, index) => {
											return (
												< tr key={rating?.key} >
													<td className='text-start'>#{index + 1}</td>
													<td className='text-start'>{rating?.key}</td>
													<td className='text-center'>{rating?.avg_rating?.value ? (<><span className="text-warning"> &#9733; </span>{rating?.avg_rating?.value.toFixed(2)}</>) : "-"}</td>
												</tr >
											)
										})
										}
									</tbody>
								</Table>
							</Spinner>
						</Card>
					</Col>
				</Row>
				<Card>
					<Card.Header>
						<Row>
							<TableKeywordSearch keyword={keyword} onChange={setKeyword} />
						</Row>
					</Card.Header>
					<Spinner display={spinner}>
						<Table responsive size='sm' className='mb-0' >
							<thead>
								<tr>
									<th className='text-center'>Shipment #</th>
									{!myShipper && <th> Shipper</th>}
									<th style={{ wordWrap: "break-word", maxWidth: "200px" }} >Patient / Feedback</th>
									<th className='text-center'>Rating</th>
									<th className='text-center'>NPS</th>
									<th className='text-center'>Date</th>
								</tr>
							</thead>
							<tbody>
								{
									ratings && ratings.map((rating) => {
										return (
											<tr key={rating?._source?.id} onClick={() => navigate(`/shipment/${rating?._source?.id}`)} style={{ cursor: 'pointer' }}>
												<td className='text-center'>{rating?._source?.number}</td>
												{!myShipper && <td >{rating?._source?.shipper?.name}</td>}
												<td style={{ wordWrap: "break-word", maxWidth: "200px" }} >
													{rating?._source?.ratings?.items[0]?.feedback || '-'}
													<div className='text-small text-muted text-wrap'>{rating?._source.shipTo?.name}</div>
												</td>
												<td className='text-center tables-handle' style={{ width: '118px' }} >
													<StarRatings rating={rating?._source?.ratings?.items[0]?.rating || 0} starRatedColor='#FFCD3C' starDimension='14px' starSpacing='1px' />
												</td>
												<td className='text-center tables-handle d-flex align-items-center justify-content-center'>
													{rating?._source?.ratings?.items[0]?.nps ? <div className={`nps nps-${rating?._source?.ratings?.items[0]?.nps * 10}`}></div> : '-'}
												</td>
												<td className='text-center'>
													{toLocalTime(rating?._source?.createdTime, rating?._source?.shipper.timezone?.id)}
													<div className='small text-muted'>{toLocalDate(rating?._source?.createdTime, rating?._source?.shipper.timezone?.id)}</div>
												</td>
											</tr>
										)
									})
								}
							</tbody>
							<TablePagination pageNumber={pageNumber} pageCount={pageCount} setPageNumber={setPageNumber} colSpan={7} />
						</Table>
					</Spinner>
				</Card>
			</Container>
		</>
	);
};

export default RatingDashboard;

